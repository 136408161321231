<div fxLayout="row" class="invitation" role="alert" aria-describedby="reponseMessage">
  <div class="success-mark">
    <mat-icon class="success-icon" svgIcon="success-icon"></mat-icon>
  </div>
  <div fxLayout="column" class="sent" id="reponseMessage">
    {{data}}</div>

  <div class="snack-icons" role="img"> 
    <a href="#" tabindex="0" id="dismiss" class="loader-close-icon" (click)="dismiss($event)">
      <mat-icon>close</mat-icon>
  </a>
  </div>
</div>