import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';

@Component({
  selector: 'app-invitation-sent',
  templateUrl: './invitation-sent.component.html',
  styleUrls: ['./invitation-sent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvitationSentComponent implements OnInit {

  constructor(private readonly _snackRef: MatSnackBarRef<InvitationSentComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.name.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-INVITATION_SENT', 'INVITATION_SENT');
      });
  }
  dismiss(event: Event): void{
    event.preventDefault();
    event.stopPropagation();
    this._snackRef.dismiss();
  }


}
