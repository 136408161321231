import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CandidateNeedsAssessmentSharedService } from '../core/services/candidateneeds-assessment-shared.service';
import { PermissionsService } from '../core/services/permissions.service';
import { AppConfigService } from '../core/services/app-config.service';
import { CookieService } from 'ngx-cookie-service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly permissionService: PermissionsService,
    private readonly candidateSrvc: CandidateNeedsAssessmentSharedService,
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const idleExpiretime = parseInt(this.cookieService.get('car-ses-time'), 10);
    const currTimeMs = new Date().getTime();
    if (!!idleExpiretime && currTimeMs > idleExpiretime) {
          this.router.navigate(['/logout']);
         } else {
                return new Promise((resolve, reject) => {
                  if (!window.navigator.userAgent.match(/(MSIE|Trident)/)) {
                    this.candidateSrvc.getCandidateNeedsAssessmentDetails().then(resp => {
                      if (!!resp && !!resp.familyDetails && !resp.familyDetails.familyRelocationStatus) {
                        if (route.url[0].path !== 'candidate-assessment') {
                          this.router.navigate(['/candidate-assessment']);
                        } else {
                          if (!this.appConfig.getConfig('byPassAuthorization') && !!route && !!route.data && !!route.data.capabilities) {
                            this.permissionService.checkAuthorization(route.data.capabilities).then(response => {
                              if (response) {
                                resolve(true);
                              } else {
                                this.router.navigate(['/contact-us']);
                                resolve(false);
                              }
                            });
                          } else {
                            resolve(true);
                          }
                        }
                      } else {
                        if (route.url[0].path === 'candidate-assessment') {
                          this.router.navigate(['/manage-move']);
                        } else {
                          if (!this.appConfig.getConfig('byPassAuthorization') && !!route && !!route.data && !!route.data.capabilities) {
                            this.permissionService.checkAuthorization(route.data.capabilities).then(response => {
                              if (response) {
                                resolve(true);
                              } else {
                                this.router.navigate(['/contact-us']);
                                resolve(false);
                              }
                            });
                          }
                        }
                      }
                    });
                  } else {
                    this.router.navigate(['/not-supported']);
                  }
              });
          }
      }
}
