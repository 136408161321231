import { Subscription } from 'rxjs';
import { ManageMoveSharedService } from './../../../core/services/manage-move-shared.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RelocationBudgetDetails } from '../../../core/models/relocation-budget.model';
import { CandidateNeedsAssessment } from '../../../core/models/candidateneeds-assessment.model';
import { CandidateNeedsAssessmentSharedService } from '../../../core/services/candidateneeds-assessment-shared.service';
import { MyProfileService } from '../../../core/services/my-profile.service';
import { ProfileDetails } from '../../../core/models/candidate-profile-details.model';
import { NotificationsService } from '../../../core/services/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyProfileComponentTemplateString, unableToProcessRequest } from '../../../core/models/constants';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';
import { AppConfigService } from '../../../core/services/app-config.service';
import { Router } from '@angular/router';
import { PermissionsService } from '../../../core/services/permissions.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  /** Holds Profile Data */
  myProfileDatails: ProfileDetails;
  /** Holds Profile Data */
  myMoveDatails: CandidateNeedsAssessment;
  /** Stores the salutation options */
  salutationOptions: string[] = ['Mr.', 'Mrs.', 'Ms.'];
  /**used to store tittle for relocation budget*/
  templateString = MyProfileComponentTemplateString;
  /** Access the selected value of salutation for DOM */
  @ViewChild('salutationSelect', { static: false }) salutationSelect;
  /**Form to capture the candidate profile details */
  myProfileform: FormGroup;
  /**Form to capture the candidate profile details */
  moveDetailsform: FormGroup;
  /** Holds the Updated Profile details */
  updatedProfile: ProfileDetails;
  /** Holds Client Contact ID */
  clientContactId = '5d8b16401c9d440000f9bdec';
  canUpdateProfileInfo = true;
  phoneNumber: any;
  emailId: any;
  countryDialingCode: any;
  /**
   * Base Constructor
   * @param formBuilder Instance of FormBuilder
   * @param manageMoveSharedSvc Instance of Manage-Move Shared Services
   * @param candidateSharedSvc instance of Candidate Needs Assessment Shared Service
   * @param notificationsService Notification service variable
   * @param notificationsService Notification service variable
   * @param spinner Instance of NgxSpinnerService
   */
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly candidateProfileSvc: MyProfileService,
    private readonly candidateSharedSvc: CandidateNeedsAssessmentSharedService,
    private readonly notificationsService: NotificationsService,
    private readonly spinner: NgxSpinnerService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly manageMoveSharedService: ManageMoveSharedService,
    private readonly appConfig: AppConfigService,
    private readonly router: Router,
    private readonly permissionsSvc: PermissionsService) {
    this.initializeProfileForm();
  }

  /** Component Initial Initialization*/
  ngOnInit() {
    this.canUpdateProfileInfo = this.permissionsSvc.getCapabilities();
    if (!this.canUpdateProfileInfo) {
      this.myProfileform.disable();
      this.moveDetailsform.disable();
      this.myProfileform.controls['Salutation'].disable();
    }
    this.spinner.show();
    this.candidateSharedSvc.getCandidateNeedsAssessmentDetails().then((resp: CandidateNeedsAssessment) => {
      if (resp) {
        this.myMoveDatails = resp;
        this.candidateProfileSvc.getCandidateProfileDetails(this.myMoveDatails.candidateId, this.clientContactId)
          .subscribe((response: ProfileDetails) => {            
            if (response) {
              this.myProfileDatails = response;
              this.myMoveDatails['highValueGoods'] = this.myProfileDatails.hasHighValueGoods && this.myProfileDatails.hasHighValueGoods.toLowerCase() == 'yes'? 'Yes' : 'No'
              this.phoneNumber = this.myProfileDatails.contactNumber;
              this.emailId = this.myProfileDatails.emailAddress;
              this.countryDialingCode = this.myProfileDatails.phoneCountryDialingCode ? this.myProfileDatails.phoneCountryDialingCode : '';
              /** Initializing the Profile Form with initial values */
              this.myProfileform.patchValue({
                Salutation: this.myProfileDatails.salutation,
                FirstName: this.myProfileDatails.firstName,
                LastName: this.myProfileDatails.lastName,
                PreferredName: this.myProfileDatails.preferredName,
                CompanyName: this.myProfileDatails.companyName,
                JobStartDate: this.myProfileDatails.jobStartDate
              }); 
              
              /** Initializing the Move-Details Form with initial values */
              this.moveDetailsform.patchValue({
                FamilyRelocationStatus: this.myMoveDatails.familyDetails.familyRelocationStatus,
                NoOfRelocatePeople: this.myMoveDatails.familyDetails.noOfRelocatePeople,
                CurrentAddress: `${this.myMoveDatails.departureAddr.streetAddress}, ${this.myMoveDatails.departureAddr.city}, ${this.myMoveDatails.departureAddr.state} ${this.myMoveDatails.departureAddr.zipcode}`,
                DestinationAddress: `${this.myMoveDatails.destinationAddr.city}, ${this.myMoveDatails.destinationAddr.state}`,
                OwnerStatus: this.myMoveDatails.residenceDetails.ownerStatus,
                HomeType: this.myMoveDatails.residenceDetails.homeType,
                NoOfRooms: this.myMoveDatails.residenceDetails.noOfRooms,
                HighValueGoods: this.myMoveDatails.highValueGoods
              });
              this.loggedInUserService.getLoggedInUserDetails()
                // tslint:disable-next-line: no-shadowed-variable
                .subscribe(response => {
                  const userId: any = response.name.replace(/ .*/, '');
                  this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-MY_PROFILE', 'MY_PROFILE');
                });
            } else {
              this.notificationsService.flashNotification(
                'failed',
                unableToProcessRequest,
                false,
                'dismiss'
              );
            }
            this.spinner.hide();
          }, err => {
            this.spinner.hide();
          });        
        this.myProfileform.disable();
        this.moveDetailsform.disable();
      } else {
        this.notificationsService.flashNotification(
          'failed',
          unableToProcessRequest,
          false,
          'dismiss'
        );
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }


  /** Form initialization */
  initializeProfileForm(): void {
    this.myProfileform = this.formBuilder.group({
      Salutation: [''],
      FirstName: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z A-Z0-9-`]*$')
      ])],
      LastName: ['', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(30),
        Validators.pattern('^[a-z A-Z0-9-`]*$')
      ])],
      PreferredName: [''],
      CompanyName: [''],
      JobStartDate: [''],
      PhoneNumber: [''],
      Email: ['']
    });

    this.moveDetailsform = this.formBuilder.group({
      FamilyRelocationStatus: [''],
      NoOfRelocatePeople: [''],
      CurrentAddress: [''],
      DestinationAddress: [''],
      OwnerStatus: [''],
      HomeType: [''],
      NoOfRooms: [''],
      HighValueGoods: ['']      
    });
  }

  /**
   * Function used to open select options
   * @param formControlName Holds Form Control name
   */
  selectClicked(formControlName) {
    this.salutationSelect.open();
    this.myProfileform.get(formControlName).clearValidators();
  }

  /**
   * Function used to set Salutation value on change
   * @param event Salutation change event
   * @param formControlName Holds form Control name
   */
  updateInput(event, formControlName) {
    if (event.value) {
      this.myProfileform.get(formControlName).setValue(event.value);
    } else if (event === false) {
      this.onBlurMethod(formControlName);
    }
  }
  /**
   * Edit the field on click of edit icon
   * @param formControlName field to edit
   */
  edit(formControlName: string): void {
    this.myProfileform.get(formControlName).enable();
    if (formControlName === 'Salutation') {
      this.selectClicked('Salutation');
    }
  }

  /**
   * Function for onblur when control is taken away from the field and Update Profile details
   * @param formControlName field to exit
   */
  onBlurMethod(formControlName) {
    if (this.myProfileform.get(formControlName).valid) {
      this.myProfileform.get(formControlName).disable();

      this.updatedProfile = {} as ProfileDetails;
      this.updatedProfile.candidateId = this.myMoveDatails.candidateId;
      this.updatedProfile.salutation = this.myProfileform.controls['Salutation'].value ?
        this.myProfileform.controls['Salutation'].value : '';
      this.updatedProfile.firstName = this.myProfileform.controls['FirstName'].value;
      this.updatedProfile.lastName = this.myProfileform.controls['LastName'].value;
      this.updatedProfile.preferredName = this.myProfileform.controls['PreferredName'].value ?
        this.myProfileform.controls['PreferredName'].value : '';

      this.candidateProfileSvc.updateCandidateProfile(this.updatedProfile)
        .subscribe(resp => {
          if (resp.statusCode !== 200) {
            this.notificationsService.flashNotification(
              'failed',
              unableToProcessRequest,
              false,
              'dismiss'
            );
          } else {
            this.manageMoveSharedService.candidateDetails.subscribe(data => {
              data.fullName = `${this.myProfileform.controls['FirstName'].value}, ${this.myProfileform.controls['LastName'].value}`;
              this.manageMoveSharedService.updateData(data);
            });
          }
        });
    }
  }

  /**
   * Custom error messages for Firstname, lastname and Email to verify special character or empty errors
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(fieldName) {
    if (fieldName === 'FIRST_NAME') {
      return this.myProfileform.get('FirstName').hasError('required')
        ? 'You must enter first name'
        : this.myProfileform.get('FirstName').hasError('pattern')
          ? 'Special characters are not allowed'
          : '';
    }
    if (fieldName === 'LAST_NAME') {
      return this.myProfileform.get('LastName').hasError('required')
        ? 'You must enter last name'
        : this.myProfileform.get('LastName').hasError('pattern')
          ? 'Special characters are not allowed'
          : '';
    }
  }

  gotoChangePasswordPage(event) {
    event.preventDefault();
    event.stopPropagation()
    const changePasswordURL = String(this.appConfig.getConfig('changePassword'));
    const url = this.router.url.length > 1 ? this.router.url.substr(1) : this.router.url;
    this.router.navigate(['/externalRedirect', { externalUrl: `${changePasswordURL}&urlPath=${url}` }], {
      skipLocationChange: true
    });
  }

}
