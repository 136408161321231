import { ManageMoveSharedService } from './../../core/services/manage-move-shared.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ManageMoveService } from '../../core/services/manage-move.service';
import { RelocationBudgetDetails } from '../../core/models/relocation-budget.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from '../../core/services/notifications.service';
import { CandidateNeedsAssessmentSharedService } from '../../core/services/candidateneeds-assessment-shared.service';
import {LoggerService} from '../../core/services/logger.service';
import { LoggedInUserService } from '../../core/services/loggedin-user-details.service';

/** base component for manage-move */
@Component({
  selector: 'app-transferee',
  templateUrl: './transferee.component.html',
  styleUrls: ['./transferee.component.scss']
})
export class TransfereeComponent implements OnInit {

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Used to hold NeedsAssesment Data */
  candidateMoveDetails: RelocationBudgetDetails;
  /** Holds Candidate ID */
  candidateId: string;
  /** mock client contact id */
  clientContactId = '5d8b16401c9d440000f9bdec';

  /**
   * Base Constructor
   * @param manageMoveService Instance of ManageMoveService
   * @param manageMoveSharedService Instance of ManageMoveSharedService
   * @param spinner Instance of NgxSpinnerService
   * @param notificationsService Notification service variable
   * @param needsAssessmentSvc Needs assessment shared service
   */
  constructor(
    private readonly manageMoveService: ManageMoveService,
    private readonly manageMoveSharedService: ManageMoveSharedService,
    private readonly spinner: NgxSpinnerService,
    private readonly notificationsService: NotificationsService,
    private readonly needsAssessmentSvc: CandidateNeedsAssessmentSharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService

  ) { }

  /**
   * Component Initial Calls and Initialization
   */
  ngOnInit() {
    this.spinner.show();
    this.needsAssessmentSvc.getCandidateNeedsAssessmentDetails().then(response => {
      this.candidateId = response.candidateId;

      this.subscription.add(
        this.manageMoveService.getRelocationBudgetDetails(this.candidateId, this.clientContactId).
          subscribe((resp: RelocationBudgetDetails) => {
            if (resp) {
              this.candidateMoveDetails = resp;
              this.candidateMoveDetails.candidateId = this.candidateId;
              if (!resp.hvgTotal) {
                this.candidateMoveDetails.hvgTotal = 0;
              }
              this.manageMoveSharedService.updateData(this.candidateMoveDetails);
              this.loggedInUserService.getLoggedInUserDetails()
                // tslint:disable-next-line: no-shadowed-variable
                .subscribe(response => {
                  const userId: any = response.name.replace(/ .*/, '');
                  this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-TRANSFEREE', 'TRANSFEREE');
                });
            } else {
              this.notificationsService.flashNotification(
                'failed',
                'We are unable to process your request at this time. Please try again later.',
                false,
                'dismiss'
              );
            }
            this.spinner.hide();
          },
            err => {
              this.spinner.hide();
            })
      );
    });
  }
  /**
   * scroll top on every router click
   */
  onActivate() {
    document.documentElement.scrollTop = 0;
  }
}
