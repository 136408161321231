import { Component, OnInit, Input } from '@angular/core';
import { StepHelp } from '../../../../core/models/step-help.model';
import {LoggerService} from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';

/**
 * Base component for right section
 */
@Component({
  selector: 'app-rightsection',
  templateUrl: './rightsection.component.html',
  styleUrls: ['./rightsection.component.scss']
})
export class RightsectionComponent implements OnInit {
  /** Input for question and value at right pane content */
  @Input() displayText: StepHelp;
  /**
   * Base constructor
   */
  constructor(private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) {}
  /**
   * Used to initialize values
   */
  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
    .subscribe(response => {
      const userId: any = response.name.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-RIGHT_SECTION', 'RIGHT_SECTION');
    });
  }
}
