<div *ngIf="transfereeDetails" class="exploreDestination" fxFlexFill fxLayout="column">
  <div class="main-content-tile custom-margin-left high-value-tile" *ngIf="transfereeDetails && transfereeDetails.hasHighValueGoods && !transfereeDetails.isAuthorized">
    <mat-card class="content-card custom-width exploreSection">
      <mat-card-header>
        <mat-card-title>
          <div fxLayout="row" fxFlexFill>
            <div class="exploreWrapper" fxFlex="90%" fxFlex.xs="90%" fxFlexFill>
              <img class="exploreIcon" src="../../../../../../assets/images/Transferee/truck.svg" aria-hidden="true" alt="HVG"/>
              <h2 role="heading" level="2" class="exploreHeading">High Value Goods</h2>
            </div>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="middle-section">
          <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
            fxLayoutGap.xs="0em">
            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <p class="fontfa">
                Please review the High Value video & FAQs to understand what items are excluded and which items are covered.
                <a class="edit-icon"  aria-label="Items that can be included in High Value Goods" href="#" (click)="openTooltipDialog('highvaluegoods', $event);isHighValueGoodsToolTipActive = !isHighValueGoodsToolTipActive"> 
                  <span>
                    <mat-icon class="icon-button" aria-label="help-icon" [ngClass]="{'tooltip-active': isHighValueGoodsToolTipActive}">help</mat-icon>
                  </span>
                </a>
              </p>
            </div>
            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
              <h3 role="heading" aria-level="3" class="total-label">
                Total of High Value Items Entered:
              </h3>
              <div class="rewards-logo-container">
                <div class="receive-rewards-container">
                  <span>{{transfereeDetails.hvgTotal | number:'.0-0'}}
                    {{currencySymbol}}</span>
                </div>
              </div>
              <div [ngClass]="{'visiblie-hidden': hvgSubmittedOn == null}">
                <div class="hvg-submitdate">
                  Items Submitted on {{hvgSubmittedOn}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="middle-section">
          <div class="input-row space" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
            fxLayoutGap.xs="0em">
            <div [ngClass]="{'visiblie-hidden': hvgSubmittedOn != null}" [class.disabled]="!canUpdateHVGItem" class="rewards-logo-btn-container" fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <button [ngClass]="{'visiblie-hidden': hvgSubmittedOn != null}" [class.disabled]="!canUpdateHVGItem" [disabled]="!canUpdateHVGItem" class="transferee-text-button border-none btnpad" (click)="openOptOutHighValueModal()">
                I Do Not Have Any High Value Goods</button>
            </div>
            <div class="rewards-logo-btn-container" fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">              
              <button class="transferee-contained-button btnpad" [routerLink]="['/manage-move/high-value-goods', transfereeDetails.candidateId]" id="refferalBtn" color="primary" >
                {{hvgSubmittedOn ? 'View High Value Items' : 'Enter High Value Items'}}</button>
            </div>          
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
  <div class="main-content-tile custom-margin-left">
    <mat-card class="content-card custom-width exploreSection">
      <mat-card-header>
        <mat-card-title>
          <div fxLayout="row" fxFlexFill>
            <div class="exploreWrapper" fxFlex="90%" fxFlex.xs="90%" fxFlexFill>
              <mat-icon class="explore" svgIcon="exploreicon"></mat-icon>
              <h2 role="heading" level="2" class="exploreHeading" aria-label="Explore Your Destination">Explore Your Destination</h2>
              <a href="http://www.onboardnavigator.com/1.5/webContent/OBWC_Results.aspx?AID=106" target="_blank" aria-label="Explore destination Open in New Window" rel="noopener noreferrer" ><img
                  src="../../../../../../assets/images/Transferee/24px (13).svg" alt="Open in New Window" aria-hidden="true" /></a>
            </div>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="exploreContainer settings-scrolldiv-horizontalbar" fxLayout="row" fxFlexFill fxLayoutGap="10px">
          <div class="explorecontentContainer" fxLayout="row">
            <div class="exploreBox">
              <h2 role="presentation"><a href="http://www.onboardnavigator.com/1.5/webContent/OBWC_Results.aspx?AID=106"
                  target="_blank" rel="noopener noreferrer">Neighborhoods<span class="cdk-visually-hidden">opens in new window</span></a></h2>
              <img src="../../../../../../assets/images/Transferee/img_neighboods.png" alt="" />
            </div>
            <div class="exploreBox">
              <h2 role="presentation"><a href="http://www.onboardnavigator.com/1.5/webContent/OBWC_Results.aspx?AID=106"
                  target="_blank" rel="noopener noreferrer" >Schools<span class="cdk-visually-hidden">opens in new window</span></a></h2>
              <img src="../../../../../../assets/images/Transferee/img_schools.png" alt="" />
            </div>
            <div class="exploreBox">
              <h2 role="presentation"><a href="http://www.onboardnavigator.com/1.5/webContent/OBWC_Results.aspx?AID=106"
                  target="_blank" rel="noopener noreferrer" >Things To Do<span class="cdk-visually-hidden">opens in new window</span></a></h2>
              <img src="../../../../../../assets/images/Transferee/img_thingsToDo.png" alt="" />
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="transfereeDetails && transfereeDetails.isAuthorized"
    class="main-content-tile custom-top-margin custom-margin-left myContact" id="services-tile">
    <mat-card class="content-card custom-width">
      <mat-card-header>
        <mat-card-title>
          <div fxLayout="row" fxFlexFill>
            <div class="exploreWrapper" fxFlex="90%" fxFlex.xs="90%" fxFlexFill>
              <mat-icon class="mycontact-section" svgIcon="contact"></mat-icon>
              <h2 class="exploreHeading">My Contacts</h2>
            </div>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="exploreContainer settings-scrolldiv-horizontalbar">
          <div>
            <div class="contactcontentContainer" fxLayout="row">
              <div class="contactMain">
                <div class="contactLeft">
                  <img src="../../../../../../assets/images/Transferee/Group 657.svg" alt="Group" />
                  Van Lines
                </div>
                <div class="contactRight">
                  <div class="contactRightInner">
                    <div
                      *ngIf="transfereeDetails && transfereeDetails.ScheduledMoveDate!=null && isAgentInfoAvailable"
                      class="scheduleInfo">
                      <div class="contactSubHead">
                        <span class="contactHd">{{services[0].company}}</span>
                        <span class="contactImg"><a [href]="services[0].website" target="_blank"><img
                              src="../../../../../../assets/images/Transferee/24px (13).svg"
                              alt="contactImg" /></a></span>
                      </div>
                      <div class="contactWrapper">
                        <div class="contactDetails"><em
                            class="material-icons person">person</em><span>{{services[0].contactName}}</span>
                        </div>
                        <div class="contactDetails"><img
                            src="../../../../../../assets/images/Transferee/baseline-call-24px.svg"
                            alt="baseline-call" /><span>{{services[0].contactNumber}}</span>
                        </div>
                        <div class="contactDetails"><img
                            src="../../../../../../assets/images/Transferee/baseline-alternate_email-24px.svg"
                            alt="email" /><span>{{services[0].contactEmail}}</span>
                        </div>
                      </div>
                    </div>

                    <!-- Pending tile starts here -->
                    <div
                      *ngIf="transfereeDetails && transfereeDetails.ScheduledMoveDate!=null && !isAgentInfoAvailable"
                      class="scheduleInfo">
                      <div class="contactSubHead">
                        <span class="contactHd">Atlas Van Lines</span>
                      </div>
                      <div class="scheduleNone pendingWrapper">
                        <div class="noneHeading">Pending</div>
                      </div>
                    </div>
                    <!-- Pending tile ends here -->

                    <!-- Schedule move tile starts here -->
                    <div *ngIf="transfereeDetails && transfereeDetails.ScheduledMoveDate===null" class="scheduleNone">
                      <div class="noneHeading">None Scheduled</div>
                      <div class="scheduleLink"><img src="../../../../../../assets/images/Transferee/24px (18).svg"
                          alt="scheduleLink" />
                        <a role="button" id="schedule-my-move" (click)="openDialog()">Schedule My Move Now</a></div>
                    </div>
                    <!-- Schedule move tile ends here -->
                  </div>
                </div>
              </div>
              <div class="contactMain"
                *ngIf="transfereeDetails && transfereeDetails.isAuthorized && transfereeDetails.ScheduledMoveDate">
                <div class="contactLeft insurance">
                  <img src="../../../../../../assets/images/Transferee/Subtraction 27.svg" alt="Subtraction" />
                  Insurance
                </div>
                <div class="contactRight">
                  <div class="contactRightInner">
                    <div
                      *ngIf="transfereeDetails && transfereeDetails.ScheduledMoveDate!=null && isAgentInfoAvailable"
                      class="scheduleInfo">
                      <div class="contactSubHead">
                        <span class="contactHd">{{services[1].company}}</span>
                        <span class="contactImg"><a [href]="services[1].website" target="_blank"><img
                              src="../../../../../../assets/images/Transferee/24px (13).svg"
                              alt="Transferee" /></a></span>
                      </div>
                      <span class="contactDetails">Order# {{services[1].orderReferenceId}}</span>
                      <div class="contactWrapper">
                        <div class="contactDetails"><em
                            class="material-icons person">person</em><span></span>
                        </div>
                        <div class="contactDetails"><img
                            src="../../../../../../assets/images/Transferee/baseline-call-24px.svg"
                            alt="baseline" /><span>{{services[1].contactNumber}}</span>
                        </div>
                        <div class="contactDetails"><img
                            src="../../../../../../assets/images/Transferee/baseline-alternate_email-24px.svg"
                            alt="email" /><span>{{services[1].contactEmail}}</span>
                        </div>
                      </div>
                    </div>

                    <!-- Pending tile starts here -->
                    <div *ngIf="transfereeDetails && !isAgentInfoAvailable" class="scheduleInfo">
                      <div class="contactSubHead">
                        <span class="contactHd">Insurance</span>
                      </div>
                      <div class="scheduleNone pendingWrapper">
                        <div class="noneHeading">Pending</div>
                      </div>
                    </div>
                    <!-- Pending tile ends here -->

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="transfereeDetails && !transfereeDetails.isAuthorized && !transfereeDetails.hasHighValueGoods"
   class="custom-margin-left  custom-top-margin preAproved" id="contact-text">
    You’ll be able to start booking your service providers and additional services once your move is approved.
  </div>
</div>