import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RelocationBudgetDetails } from '../../../core/models/relocation-budget.model';
import { ManageMoveSharedService } from '../../../core/services/manage-move-shared.service';
import { TimelineService } from '../../../core/models/timeline-service.model';
import {LoggerService} from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';

/** base component for timeline checklist */
@Component({
  selector: 'app-timeline-checklist',
  templateUrl: './timeline-checklist.component.html',
  styleUrls: ['./timeline-checklist.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimelineChecklistComponent implements OnInit {
  /** values to be displayed in HTML */
  templateString = {
    HEADING: 'My Relocation Timeline & Tasks',
    HEADING_SUMMARY:
      `My Relocation Timeline presents a visual roadmap of key dates associated to your
       move with checklist activities to help you proactively stay on plan.`,
    GEN_FAQ_TITLE: 'General Information FAQ'
  };
  /** Used to hold Candidate Move Data */
  candidateMoveDetails: RelocationBudgetDetails;
  /**  used to hold transferee timeline data */
  timelineDetails: TimelineService[];
  /**
   * base constructor
   * @param moveSharedService shared service for move data
   */
  constructor(
    private readonly moveSharedService: ManageMoveSharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }
  /** function to be called on init */
  ngOnInit() {
    this.moveSharedService.candidateDetails.subscribe(candidateDetails => {
      this.candidateMoveDetails = candidateDetails;
      this.timelineDetails = candidateDetails.timeLineDates;
    });
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.name.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-TIMELINE_CHECKLIST', 'TIMELINE_CHECKLIST');
      });
  }

}
