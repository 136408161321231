import { Subscription } from 'rxjs';
import { CandidateNeedsAssessmentSharedService } from './../../../../core/services/candidateneeds-assessment-shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from './../../../../core/services/notifications.service';
import { AnswerList, Mytask, QuestionList, ChecklistDetails } from './../../../../core/models/timeline-checklist-details.model';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ManageMoveService } from './../../../../core/services/manage-move.service';
import * as constants from './../../../../core/models/constants';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';
import { PermissionsService } from '../../../../core/services/permissions.service';

/** base component for checklist */
@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChecklistComponent implements OnInit, OnDestroy {
  /** Mytask array*/
  mytaskArray: Mytask[];
  /** variable for ChecklistDetails*/
  checklistDetails: ChecklistDetails;
  /** candidate id */
  candidateId;
  /** checklist title */
  title = constants.checklistTitle;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  canUpdateChecklist = true;

  /** base constructor */
  constructor(private readonly manageMoveService: ManageMoveService,
    private readonly notificationsService: NotificationsService,
    private readonly spinner: NgxSpinnerService,
    private readonly needsAssessmentSvc: CandidateNeedsAssessmentSharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly permissionsSvc: PermissionsService) { }
  /** function to be called on init */
  ngOnInit() {
    this.canUpdateChecklist = this.permissionsSvc.getCapabilities();
    this.spinner.show();
    this.needsAssessmentSvc.getCandidateNeedsAssessmentDetails().then(resp => {
      if (resp) {
        this.candidateId = resp.candidateId;
        this.subscription.add(
          this.manageMoveService.getTimelineChecklistDetails(this.candidateId).subscribe(response => {
            if (response && response.statusCode === undefined) {
              this.mapChecklistDetails(response);
            } else {
              this.notificationsService.verticalPosition = 'bottom';
              this.notificationsService.autoHide = 5000;
              this.notificationsService.flashNotification(
                'failed',
                constants.apiFailureResponseMsg,
                false,
                'dismiss'
              );
            }
            this.spinner.hide();
          },
            err => {
              this.spinner.hide();
            })
        );
      }
    });
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.name.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-CHECKLIST', 'CHECKLIST');
      });
  }
  /** formatting the api response to ui format
   * @param checkListResponse - api response data of timeline checklist
   */
  mapChecklistDetails(checkListResponse: any): void {
    this.mytaskArray = [];
    const mytask: Mytask = {} as Mytask;
    const questionList: QuestionList[] = [];
    let questionArray: QuestionList = {} as QuestionList;
    let answerList: AnswerList[] = [];
    let answerArray: AnswerList = {} as AnswerList;
    let count = 0;
    checkListResponse.map(taskList => {
      taskList.taskDetail.forEach(taskDetails => {
        answerArray = {} as AnswerList;
        answerArray.content = taskDetails.taskDisplayValue;
        answerArray.type = constants.questionType;
        answerArray.isChecked = taskDetails.isCompleted;
        answerArray.taskId = taskDetails.taskId;
        answerArray.taskCode = taskList.taskCode;
        if (!taskDetails.isCompleted) {
          count = count + 1;
        }
        answerList.push(answerArray);
      });

      questionArray.title = constants.myTaskList[taskList.taskCode];
      questionArray.remainingCount = count;
      questionArray.answer = answerList;
      questionList.push(questionArray);
      answerList = [];
      questionArray = {} as QuestionList;
      count = 0;
    });
    mytask.title = constants.myTaskListTitle;
    mytask.questions = questionList;
    this.mytaskArray.push(mytask);
  }

  /** method to updateing the checklist remaining count and saving the checklist details in db
   * @param isChecked - checkbox data
   * @param answer - type of AnswerList
   * @param taskId - refers unique task id
   * @param remainingCount - unchecked checkbox count
   */
  toggleCheckbox(isChecked: boolean, taskId: string, remainingCount: number, index: number): void {
    this.checklistDetails = {} as ChecklistDetails;
    remainingCount = isChecked ? remainingCount - 1 : remainingCount + 1;
    this.mytaskArray[0].questions[index].remainingCount = remainingCount;
    this.checklistDetails.candidateId = this.candidateId;
    this.checklistDetails.isCompleted = isChecked;
    this.checklistDetails.taskId = taskId;
    this.subscription.add(
      this.manageMoveService.saveChecklistDetails(this.checklistDetails).subscribe(response => {
        if (response && response.statusCode !== 200) {
          this.notificationsService.verticalPosition = 'bottom';
          this.notificationsService.flashNotification(
            'failure',
            constants.apiFailureResponseMsg,
            true,
            'dismiss'
          );
        }
      })
    );
  }

  /**
   * Destroys the subscription object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
