<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>
<div class="high-value-page transfree-regular-page">
    <app-budget-header [subtitle_margin_bottom_none]=isHighValueSubmitted [heading]='templateString.HEADING' [summary]='templateString.HEADING_SUMMARY'
        [backNavLink]="'/manage-move'">
    </app-budget-header>
    <mat-accordion *ngIf="!hvgSubmittedOn">
        <div class="itemEntry">
            <span style="padding-left: 10px;">
                <span>
                    <mat-icon class="highvaluemenu-icon" svgIcon="highvaluemenu">
                    </mat-icon>
                </span>
                <span class="item-entry-label">Item Entry Directions</span>
            </span></div>
        <mat-expansion-panel class="panel-1">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    VALUE EXCEEDS $2,500
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="catrgory-value">
                <li *ngFor="let catValue of category3">{{catValue.value | uppercase}}</li>
            </ul>
            <p class="highValueDisclaimer">Please do not list the above items if they are $2,500 or less</p>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    VALUE EXCEEDS $5,000
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="catrgory-value">
                <li *ngFor="let catValue of category2">{{catValue.value | uppercase}}</li>
            </ul>
            <p class="highValueDisclaimer">Please do not list the above items if they are $5,000 or less</p>

        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    VALUE EXCEEDS $10,000
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="catrgory-value">
                <li *ngFor="let catValue of category1">{{catValue.value | uppercase}}</li>
            </ul>
            <p class="highValueDisclaimer">Please do not list the above items if they are $10,000 or less</p>
        </mat-expansion-panel>
    </mat-accordion>
    <p  tabindex="0" class="info-subtitle" *ngIf="!hvgSubmittedOn">
        Please review the High Value video & FAQs. Its very important to Understand what is covered or excluded, and how
        to value your belongings to ensure the correct coverage.
        Items not reported may result in limited coverage.
    </p>
    <div tabindex="0" *ngIf="!(highValueGoods && highValueGoods.length > 0)" class="pre-approved" id="contact-text">
        No items added yet.
    </div>
    <div *ngIf="highValueGoods && highValueGoods.length > 0">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="1em"
            fxLayoutGap.xs="0.1em" class="itmlst container">
            <div fxFlex="15%" fxLayoutAlign="start" fxFlex.xs="100%" class="high-value-header">
                <div aria-live="polite" aria-atomic="true">
                    <img alt="my-items" src="../../../../../../assets/images/Transferee/list_alt-24px.svg" aria-hidden="true" />
                    <span class="my-items-label">My Items</span>
                </div>
            </div>
            <div fxFlex="85%" fxFlex.xs="100%" class="high-value-header" class="sort-options">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="1em"
                    fxLayoutGap.xs="0.1em" class="itmlst ">
                    <div fxFlex="10%" fxLayoutAlign="start" fxFlex.xs="100%" class="high-value-header">
                        <div aria-live="polite" aria-atomic="true">
                            <img alt="sort" src="../../../../../../assets/images/Transferee/sort-24px.svg" aria-hidden="true" />
                            <span class="my-items-label color-medium-grey" aria-label="sort">Sort:</span>
                        </div>
                    </div>
                    <div fxFlex="90%" fxFlex.xs="100%" class="high-value-header select-options">
                        <mat-form-field>
                            <mat-select class="mat-sort-select-value" aria-labelledby="Sort" [(ngModel)]="selectedSortOption" (ngModelChange)="onSortOptionChange()">
                                <mat-option *ngFor="let sortOption of sortOptions" [value]="sortOption.value">
                                {{sortOption.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxLayoutAlign="end" fxFlex.xs="100%" class="active-addicon" *ngIf="!hvgSubmittedOn">
                <a href="#" (click)="addHighValueItem($event)">
                    <span class="cdk-visually-hidden">Add High Value Item</span>
                    <mat-icon aria-hidden="false" matPrefix id="add_icon" matTooltip="add item">add_circle</mat-icon>
                </a>
            </div>
        </div>
        <mat-card class="services-card transfree-content-card margin-bottom" *ngFor="let item of highValueGoods">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayoutGap.xs="0.1em">
                <div fxFlex="20%" fxLayoutAlign="start" fxFlex.xs="100%" class="transferee-toppadding">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Type of Item</mat-label>
                        <input matInput readonly [value]="item.itemType">
                    </mat-form-field>
                </div>
                <div fxFlex="30%" fxLayoutAlign="start" fxFlex.xs="100%" class="transferee-toppadding">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Description of Item</mat-label>
                        <input matInput readonly [value]="item.description">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxFlex="45%" fxLayoutAlign="space-between" fxLayoutGap="1em"
                    fxLayoutGap.xs="1em">
                    <div fxFlex="54%" fxLayoutAlign="start" fxFlex.xs="100%" class="transferee-toppadding">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Replacement Value</mat-label>
                            <input matInput readonly value="{{item.replacementValue ? (item.replacementValue | number:'.0-0') : 0}} {{currencySymbol}}">
                        </mat-form-field>
                    </div>
                    <div *ngIf="!hvgSubmittedOn" fxFlex="20%" fxLayoutAlign="end" fxFlex.xs="40%" class="transferee-toppadding">
                        <a href="#" (click)="canUpdateHVGItem ? deleteHVGItem(item._id, item.itemType, $event) : null;" [class.disabled]="!canUpdateHVGItem" matTooltip="delete">
                            <img alt="delete" src="../../../../../../assets/images/Transferee/delete_icon.svg" aria-hidden="false" class='delete_icon'/>
                        </a>
                        <a href="#" (click)="editOpen(item, $event)"  matTooltip="edit">
                            <img alt="edit" class="edit-icon" src="../../../../../../assets/images/Transferee/edit-24px.svg" aria-hidden="false" />
                        </a>
                    </div>
                    <div *ngIf="hvgSubmittedOn" fxFlex="40%" fxFlex.xs="35%" fxLayoutAlign="end" class="transferee-toppadding optional-id">
                        <mat-form-field style="width: 100%;">
                            <mat-label>ID Number (optional)</mat-label>
                            <input matInput readonly value="{{item.serialNum ? item.serialNum : 'N/A'}}">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

    <button *ngIf="!(highValueGoods && highValueGoods.length > 0) && !hvgSubmittedOn" aria-label="Add high value item"
        class="transferee-contained-button adding-high-value-item-button custom-bottom-margin margin-top" (click)="addHighValueItem($event)">
        {{templateString.ADD_HIGH_BUTTON}}
    </button>

    <div *ngIf="hvgSubmittedOn" class="total-submitted">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="1em"
            fxLayoutGap.xs="0.1em" class="itmlst total-container">
            <div fxFlex="20%" fxLayoutAlign="start" fxFlex.xs="100%" class="total-div">
                <div aria-live="polite" aria-atomic="true" tabindex="0">
                    <p>
                        <span class="total-label">Total: </span>
                        <span class="total-amount">{{highValueGoodsTotalAmount ? (highValueGoodsTotalAmount | number:'.0-0') : 0}}
                            {{currencySymbol}}</span>
                    </p>
                </div>
            </div>
            <div fxFlex="80%" fxFlex.xs="100%" class="high-value-header" class="submitted-on">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="1em"
                    fxLayoutGap.xs="0.1em" class="itmlst">
                    <div fxFlex="25%" fxLayoutAlign="start" fxFlex.xs="100%" class="total-div submit-label">
                        <div aria-live="polite" aria-atomic="true" tabindex="0">
                            <p>
                                <span class="total-label">Submitted on {{hvgSubmittedOn | date:'yyyy-MM-dd'}}</span>
                            </p>
                        </div>
                    </div>
                    <div fxFlex="75%" fxFlex.xs="100%" class="high-value-header select-options">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="notice">
        <p tabindex="0">Listing an item does not guarantee coverage if goods are specifically excluded from the insurance policy,
            i.e. jewelry (including watches and cuff links), cash, coins, sports memorabilia etc.
            Underwriter may require substantiation of value and/or ownership. Inability to provide may affect coverage.
        </p>
    </div>
</div>
<mat-card class="services-card transfree-content-card button-card" *ngIf="!hvgSubmittedOn">
    <div class="btns" fxLayout="row" fxLayoutAlign="space-between" fxLayout.xs="column"
        fxLayoutGap.xs="0em">
        <div class="Hvg_total" fxFlex="17%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <p class="high-value-total">
                <span>Total of Items Entered:</span><br />
                <span class="high-value-amount">{{highValueGoodsTotalAmount | number:'.0-0'}}
                    {{currencySymbol}}</span>
            </p>
        </div>
        <div fxFlex="26%" fxLayout.xs="column" class="submit-high-value" fxLayoutAlign="end" fxFlex.xs="100%">              
            <button class="transferee-contained-button high-value-submit" aria-label="Submit Listed Items"
                [disabled]="!(highValueGoods && highValueGoods.length > 0) || !canUpdateHVGItem" (click)="openSubmitModal()">
                Submit {{highValueGoods ? highValueGoods.length: ''}} Listed Items
            </button>
        </div>          
    </div>
</mat-card>