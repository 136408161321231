import { Component, OnInit } from '@angular/core';
import {LoggerService} from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';

@Component({
  selector: 'app-budget-details',
  templateUrl: './budget-details.component.html',
  styleUrls: ['./budget-details.component.scss']
})
export class BudgetDetailsComponent implements OnInit {

  constructor(private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
    .subscribe(response => {
      const userId: any = response.name.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-BUDGET_DETAILS', 'BUDGET_DETAILS');
    });
  }

}
