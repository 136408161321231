import { Component, OnInit, Input } from '@angular/core';
import { RelocationBudgetDetails } from '../../../core/models/relocation-budget.model';
import {LoggerService} from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {

  /** Recieving transferee Details from parent component */
  @Input() transfereeDetails: RelocationBudgetDetails;
  /**
   * Base Constructor
   */
  constructor(private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  /**
   * Component Initial Calls and Initialization
   */
  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
    .subscribe(response => {
      const userId: any = response.name.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-LEFT_SIDEBAR', 'LEFT_SIDEBAR');
    });
  }

}
