var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, NgZone } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material';
import { Router, NavigationEnd } from '@angular/router';
import { AppConfigService } from './core/services/app-config.service';
import { CandidateNeedsAssessmentService } from './core/services/candidateneeds-assessment.service';
import { CandidateNeedsAssessmentSharedService } from './core/services/candidateneeds-assessment-shared.service';
import { SessionTimeoutComponent } from './public/transferee/session-timeout/session-timeout.component';
import { LoggedInUserService } from './core/services/loggedin-user-details.service';
import { PermissionsService } from './core/services/permissions.service';
/** Base application component */
export class AppComponent {
    /**
     * Standard base constructor
     * @param matIconRegistry Instance of MatIconRegistry
     * @param domSanitizer Instance of DomSanitizer
     * @param cookieService Instance of CookieService
     * @param appConfig Instance of AppConfigService
     * @param router Instance of Router
     * @param candidateService Instance of CandidateNeedsAssessmentService
     * @param needsAssessmentSvc Instance of CandidateNeedsAssessmentSharedService
     * @param ngZone Instance of NgZone
     * @param snackbar Instance of MatSnackBar
     */
    constructor(matIconRegistry, domSanitizer, cookieService, appConfig, router, loggedInUserService, candidateService, needsAssessmentSvc, ngZone, snackbar, permissionService) {
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.cookieService = cookieService;
        this.appConfig = appConfig;
        this.router = router;
        this.loggedInUserService = loggedInUserService;
        this.candidateService = candidateService;
        this.needsAssessmentSvc = needsAssessmentSvc;
        this.ngZone = ngZone;
        this.snackbar = snackbar;
        this.permissionService = permissionService;
        /** Session Timeout */
        // MouseOver event
        this.lastX = 0;
        this.lastY = 0;
        this.idleTimeoutMinutes = 15; // Idle Session Timeout in Minutes
        this.idleWarningMsgMinutes = 1;
        this.idlelastActionKeyName = 'lastAction';
        this.pollInterval = 1000;
        this.warningMsg = 'You will be logged out in a minute due to inactivity'; // Warning Message
        this.horizontalPosition = 'center';
        this.verticalPosition = 'bottom';
        this.sessionTimeoutFlag = true;
        matIconRegistry.addSvgIcon('thumbs-up', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/candidate-assessment/yes-thumb.svg'));
        matIconRegistry.addSvgIcon('thumbs-down', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/candidate-assessment/no-thumb.svg'));
        matIconRegistry.addSvgIcon('personFilled', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/person_filled.svg'));
        matIconRegistry.addSvgIcon('person-two', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/candidate-assessment/person-two.svg'));
        matIconRegistry.addSvgIcon('person-three', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/candidate-assessment/person-three.svg'));
        matIconRegistry.addSvgIcon('person-four', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/candidate-assessment/person-four.svg'));
        matIconRegistry.addSvgIcon('own', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/candidate-assessment/own-icon.svg'));
        matIconRegistry.addSvgIcon('rent', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/candidate-assessment/rent.svg'));
        matIconRegistry.addSvgIcon('home', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/candidate-assessment/house.svg'));
        matIconRegistry.addSvgIcon('apartment', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/candidate-assessment/apartment.svg'));
        matIconRegistry.addSvgIcon('town', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/candidate-assessment/townhouse.svg'));
        matIconRegistry.addSvgIcon('realestate', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/Rewards-icon.svg'));
        matIconRegistry.addSvgIcon('notificationbell', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/notification-bell-icon.svg'));
        matIconRegistry.addSvgIcon('exploreicon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/baseline-near_me-24px.svg'));
        matIconRegistry.addSvgIcon('truck', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/truck.svg'));
        matIconRegistry.addSvgIcon('contact', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/Contact.svg'));
        matIconRegistry.addSvgIcon('infoicon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/Info_icon.svg'));
        matIconRegistry.addSvgIcon('lockicon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/Lock.svg'));
        matIconRegistry.addSvgIcon('notesicon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/Notes.svg'));
        matIconRegistry.addSvgIcon('profileIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/profile_icon.svg'));
        matIconRegistry.addSvgIcon('logoutIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/logout_icon.svg'));
        matIconRegistry.addSvgIcon('editIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/edit_icon.svg'));
        matIconRegistry.addSvgIcon('success-icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/success-icon.svg'));
        matIconRegistry.addSvgIcon('checkListIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/check_list_icon.svg'));
        matIconRegistry.addSvgIcon('eye-icon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/Eye-icon.svg'));
        matIconRegistry.addSvgIcon('logoCartusWhite', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/logo_cartus_white.svg'));
        matIconRegistry.addSvgIcon('calenderMasked', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/calender_masked.svg'));
        matIconRegistry.addSvgIcon('logoMobilifyWhite', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/Mobilify-H-Logo.svg'));
        matIconRegistry.addSvgIcon('signOut', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/sign-out-icon.svg'));
        matIconRegistry.addSvgIcon('checkCircle', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/check_circle.svg'));
        matIconRegistry.addSvgIcon('checkcircleoutline', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/check_circle_outline.svg'));
        matIconRegistry.addSvgIcon('highvaluemenu', domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/Transferee/menu_book-24px.svg'));
    }
    /** Get cards for display and set view title */
    ngOnInit() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && window.ga) {
                // Google Analytics
                window.ga('set', 'page', `${window.location.pathname}#${event.urlAfterRedirects}`);
                window.ga('send', 'pageview');
            }
        });
        if (window.ga) {
            const googleAnalyticsConfig = this.appConfig.getConfig('google_analytics');
            window.ga('create', googleAnalyticsConfig.key, 'auto'); // Start Google Analytics session
        }
        const idleExpiretime = parseInt(this.cookieService.get('car-ses-time'), 10);
        const currTimeMs = new Date().getTime();
        if (!!idleExpiretime && currTimeMs > idleExpiretime) {
            this.router.navigate(['/logout']);
        }
        this.loggedInUserService.getLoggedInUserDetails().subscribe((response) => __awaiter(this, void 0, void 0, function* () {
            sessionStorage.setItem('car-ses-con', response.userId);
            yield this.permissionService.getRoleCapabilities(response.userId);
            this.candidateService.getCandidateNeedsAssessment().subscribe((resp) => {
                if (resp) {
                    this.needsAssessmentSvc.updateCandidateNeedsAssesment(resp);
                }
            });
        }));
        this.title = 'Angular App';
        this.startIdleTimeCountDown();
        if (!window.navigator.userAgent.match(/(MSIE|Trident)/)) {
            /*Checking for cookies availability else redirect to login*/
            const sessionCookie = this.cookieService.get('car-ses-tok');
            if (sessionCookie == null || sessionCookie.length === 0) {
                this.logout();
            }
        }
        else {
            this.router.navigate(['not-supported']); // redirect to non-supported url.
        }
    }
    // Session Timeout
    /** Listen for mouse events */
    onMouseMove(e) {
        if (this.sessionTimeoutFlag && (e.pageX !== this.lastX || e.pageY !== this.lastY)) {
            this.lastX = e.pageX;
            this.lastY = e.pageY;
            this.refreshIdleCookie();
        }
    }
    /** logout of the application.  Logout URL handled by configuration */
    // logout() {
    //   sessionStorage.clear();
    //   const logoutURL = this.appConfig.getConfig('login')
    //   this.router.navigate(['/externalRedirect', { externalUrl: logoutURL }], {
    //     skipLocationChange: true
    //   });
    // }
    /** Listen for keypress events */
    onKeyPress() {
        if (this.sessionTimeoutFlag) {
            this.refreshIdleCookie();
        }
    }
    /** Start idle timeout feature */
    startIdleTimeCountDown() {
        this.refreshIdleCookie();
        this.ngZone.runOutsideAngular(() => {
            setTimeout(this.checkIdle.bind(this), this.pollInterval);
        });
    }
    /** Refresh idle timeout cookie */
    refreshIdleCookie() {
        if (this.sessionTimeoutFlag) {
            const idleExpireMs = (new Date().getTime() + (this.idleTimeoutMinutes * 60000) - 500);
            sessionStorage.setItem(this.idlelastActionKeyName, idleExpireMs.toString());
        }
    }
    /** Check idle timeout status */
    checkIdle() {
        this.ngZone.run(() => {
            const idleExpireMs = parseInt(sessionStorage.getItem(this.idlelastActionKeyName), 10);
            const idleWarningMsgTime = (idleExpireMs - (this.idleWarningMsgMinutes * 60000) - 500);
            const currTimeMs = new Date().getTime();
            if (currTimeMs > idleExpireMs) {
                this.snackbar.dismiss();
                this.logout();
            }
            else {
                if ((new Date(currTimeMs).toString() === new Date(idleWarningMsgTime).toString())) {
                    this.sessionTimeoutFlag = false;
                    this.snackbar.openFromComponent(SessionTimeoutComponent, {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        data: this.warningMsg
                    }).onAction().subscribe(data => {
                        this.sessionTimeoutFlag = true;
                        this.refreshIdleCookie();
                    });
                }
                this.ngZone.runOutsideAngular(() => {
                    setTimeout(this.checkIdle.bind(this), this.pollInterval); // Check for one second
                });
            }
        });
    }
    logout() {
        this.router.navigate(['logout']); // Redirect to Login page
    }
    // Session Timeout
    delay(ms) {
        return __awaiter(this, void 0, void 0, function* () {
            yield new Promise(resolve => setTimeout(() => resolve(), ms)).then();
        });
    }
}
