<div class="page-heading" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center">
        <div class="stepper-Previousbtn" id="back">
            <button [routerLink]="[backNavLink]">
                <span class="cdk-visually-hidden">Back to previous page</span>
                <em class="material-icons" title="Back to previous page" aria-hidden="true">keyboard_backspace</em>
            </button>
        </div>
        <h1 class="header-title">
            {{heading}}
        </h1>
    </div>
    <div class="header-sub-title" [ngClass]="{ 'margin-bottom-none': subtitle_margin_bottom_none }" tabindex="0" [innerHtml]="summary"></div>
</div>