import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialogRef, DateAdapter, MAT_DATE_FORMATS, MAT_DIALOG_DATA } from '@angular/material';
import { DateAdapterService } from '../../../core/services/date-adapter.service';
import { DatePipe } from '@angular/common';
import { ScheduleMove } from '../../../core/models/schedule-move.model';
import { NotificationsService } from '../../../core/services/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { APIResponse } from '../../../core/models/response.model';
import { Address } from '../../../core/models/address.model';
import { RelocationBudgetDetails } from '../../../core/models/relocation-budget.model';
import { ManageMoveSharedService } from '../../../core/services/manage-move-shared.service';
import * as constants from '../../../core/models/constants';
import { ManageMoveService } from '../../../core/services/manage-move.service';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import * as moment from 'moment';

/** Used to store the errormessages */
export const errorMessages: { [key: string]: string } = {
  Contact: 'You must enter Contact number',
  PhoneNumbervalidity: 'Special characters are not allowed',
  Email: 'You must enter Email address',
  City: 'You must enter City',
  State: 'You must enter State',
  StartDate: 'You must enter Start Date',
  EndDate: 'You must enter End Date'
};

/** Used to store date formats */
export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input'
  }
};

/** Base component to review and submit candidate details */
@Component({
  selector: 'app-schedule-move',
  templateUrl: './schedule-move.component.html',
  styleUrls: ['./schedule-move.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: DateAdapterService },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})

export class ScheduleMoveComponent implements OnInit, OnDestroy {
  /** Variables for error */
  errors = errorMessages;
  /** Instance of FormGroup to hold form control */
  scheduleMoveForm: FormGroup;
  /** Used to hold Transferee Details */
  candidateMoveDetails: RelocationBudgetDetails;
  /** Object Holds the candidate schedule move details */
  requestedScheduleMove: ScheduleMove;
  /** Schedule Move Info*/
  scheduleMoveInfo = constants.scheduleMoveMesssage;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Used to store min start date value */
  minStartDate: string;
  /** Used to store min end date value */
  minEndDate: string;
  /** state list from contamt file*/
  stateList = constants.stateList;
  clientContactID = '5d8b16401c9d440000f9bdec';
  canScheduleMove: any;

  /**
   * Base constructor to inject dependencies
   * @param formBuilder Instance of FormBuilder
   * @param scheduleSvc Instance of ScheduleMove Services
   * @param datePipe Instance of DatePipe
   * @param dialogRef Instance of MatDialogRef
   * @param notificationsService Notification service variable
   * @param spinner Object for spinner,
   * @param data Candidate data
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RelocationBudgetDetails,
    private readonly formBuilder: FormBuilder,
    private readonly manageMoveSvc: ManageMoveService,
    private readonly datePipe: DatePipe,
    public dialogRef: MatDialogRef<ScheduleMoveComponent>,
    private readonly notificationsService: NotificationsService,
    public spinner: NgxSpinnerService,
    private readonly permissionsSvc: PermissionsService,
    private readonly managemoveSharedSvc: ManageMoveSharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService

  ) {
    this.candidateMoveDetails = this.data;
    this.initializeScheduleMoveForm();
  }

  /** Initialization */
  ngOnInit() {
    this.canScheduleMove = this.permissionsSvc.getCapabilities();
    /** Holds current date Plus 14 days */
    
    const currentDatePlusDays = moment().add(14,'days');
    console.log(`datediff 1: ${moment.duration(currentDatePlusDays.diff(moment(this.candidateMoveDetails.estimatedMoveStartDate))).asHours()}`)
    if(moment(this.candidateMoveDetails.estimatedMoveStartDate) > currentDatePlusDays){
      this.candidateMoveDetails.estimatedMoveStartDate = moment(this.candidateMoveDetails.estimatedMoveStartDate).format('YYYY-MM-DD')
    }else{
      this.candidateMoveDetails.estimatedMoveStartDate = currentDatePlusDays.format('YYYY-MM-DD')
    }

    const startdate = moment(this.candidateMoveDetails.estimatedMoveStartDate);
    const startDatePlusDays = startdate.add(14,'days');

    if (moment(this.candidateMoveDetails.estimatedMoveEndDate) > startDatePlusDays) {
      this.candidateMoveDetails.estimatedMoveEndDate = moment(this.candidateMoveDetails.estimatedMoveEndDate).format('YYYY-MM-DD')
    } else {
      this.candidateMoveDetails.estimatedMoveEndDate = startDatePlusDays.format('YYYY-MM-DD')
    }
    this.minStartDate = moment(this.candidateMoveDetails.estimatedMoveStartDate).format('YYYY-MM-DD');
    this.minEndDate = startDatePlusDays.format('YYYY-MM-DD');

    /** Initializing the Form with initial values */
    this.scheduleMoveForm.patchValue({
      Email: this.candidateMoveDetails.emailAddress,
      PhoneNumber: this.candidateMoveDetails.contactNumber,
      EstimatedMoveStartDate: this.candidateMoveDetails.estimatedMoveStartDate,
      EstimatedMoveEndDate: this.candidateMoveDetails.estimatedMoveEndDate,
      StreetAddress: this.candidateMoveDetails.destinationAddr.streetAddress,
      City: this.candidateMoveDetails.destinationAddr.city,
      State: this.candidateMoveDetails.destinationAddr.state,
      Zipcode: this.candidateMoveDetails.destinationAddr.zipcode,
      Comments: this.candidateMoveDetails.comments
    });
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.name.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-SCHEDULE_MOVE', 'SCHEDULE_MOVE');
      });
  }

  canSubmitScheduleMove() {
    return this.scheduleMoveForm.valid && this.canScheduleMove;
  }

  /** Form initialization */
  initializeScheduleMoveForm(): void {
    this.scheduleMoveForm = this.formBuilder.group({
      Email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[\\w\\.%+-]+@[\\w]+[\\w-]*\\.[\\w]{2,3}$')
      ])],
      PhoneNumber: ['', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        this.regexValidator(new RegExp(/[a-zA-Z]/g), { 'alphabets': true }),
        this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\] ]/g), { 'splcharacter': true })
      ])],
      EstimatedMoveStartDate: ['', Validators.required],
      EstimatedMoveEndDate: ['', Validators.required],
      StreetAddress: [''],
      City: ['', Validators.required],
      State: ['', Validators.required],
      Zipcode: [''],
      Comments: ['']
    });
  }

  /**
   * Regex validation.Return true if value matches with given regex
   * @param regex Regular expression
   * @param error instence of ValidationErrors
   */
  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value.match(regex);
      return valid ? error : null;
    };
  }

  /**
   * Function used to change End date Whenever Start date changes
   * @param changedStartDate new start date
   */
  changeEstimatedEndDate(changedStartDate): void {
    const changeEnddate = new Date(
      changedStartDate.getFullYear(),
      changedStartDate.getMonth(),
      changedStartDate.getDate() + 14);
    this.candidateMoveDetails.estimatedMoveEndDate = this.datePipe.transform(
      changeEnddate,
      'yyy-MM-dd');
    this.minEndDate = moment(this.candidateMoveDetails.estimatedMoveEndDate).format('yyyy-MM-dd');
    this.scheduleMoveForm.controls['EstimatedMoveEndDate'].setValue(this.candidateMoveDetails.estimatedMoveEndDate);
  }

  /**
   * Custom error messages for Email to verify and set required errors
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(fieldName): string {
    return this.scheduleMoveForm.get('Email').hasError('required')
      ? 'You must enter email address'
      : this.scheduleMoveForm.get('Email').hasError('pattern')
        ? 'You must enter a valid email address'
        : '';
  }

  /**
   * Function to send shedule move data to initiate Services.
   */
  sendRequest() {
    this.requestedScheduleMove = {} as ScheduleMove;
    this.spinner.show();
    this.requestedScheduleMove.candidateId = this.candidateMoveDetails.candidateId;
    this.requestedScheduleMove.clientContactId = this.clientContactID;
    this.requestedScheduleMove.preferredEmailAddress = this.scheduleMoveForm.controls['Email'].value;
    this.requestedScheduleMove.preferredPhoneNumber = this.scheduleMoveForm.controls['PhoneNumber'].value.replace(/[-\(\) ]/g, '');
    this.requestedScheduleMove.estimatedMoveStartDate = this.datePipe.transform(
      this.scheduleMoveForm.controls['EstimatedMoveStartDate'].value, 'yyy-MM-dd'
    );
    this.requestedScheduleMove.estimatedMoveEndDate = this.datePipe.transform(
      this.scheduleMoveForm.controls['EstimatedMoveEndDate'].value, 'yyy-MM-dd'
    );
    this.requestedScheduleMove.destinationAddr = {} as Address;
    this.requestedScheduleMove.destinationAddr.city = this.scheduleMoveForm.controls['City'].value;
    this.requestedScheduleMove.destinationAddr.state = this.scheduleMoveForm.controls['State'].value;
    this.requestedScheduleMove.destinationAddr.streetAddress = this.scheduleMoveForm.controls['StreetAddress'].value
      ? this.scheduleMoveForm.controls['StreetAddress'].value : '';
    this.requestedScheduleMove.destinationAddr.fullAddress = this.scheduleMoveForm.controls['City'].value
      .concat(', ').concat(this.scheduleMoveForm.controls['State'].value);
    this.requestedScheduleMove.destinationAddr.zipcode = this.scheduleMoveForm.controls['Zipcode'].value
      ? this.scheduleMoveForm.controls['Zipcode'].value : '';
    this.requestedScheduleMove.comments = this.scheduleMoveForm.controls['Comments'].value
      ? this.scheduleMoveForm.controls['Comments'].value : ' ';
    this.requestedScheduleMove.moveRegisteredDate = new Date().toISOString().substring(0, 10);

    this.manageMoveSvc.sendScheduleMoveRequest(this.requestedScheduleMove).subscribe((resp: APIResponse) => {
      if (resp.statusCode === 200) {
        this.candidateMoveDetails.estimatedMoveStartDate = this.requestedScheduleMove.estimatedMoveStartDate;
        this.candidateMoveDetails.estimatedMoveEndDate = this.requestedScheduleMove.estimatedMoveEndDate;
        this.candidateMoveDetails.ScheduledMoveDate = this.requestedScheduleMove.moveRegisteredDate;
        this.managemoveSharedSvc.updateData(this.candidateMoveDetails);
        this.dialogRef.close(true);
      } else {
        this.spinner.hide();
        this.onNoClick();
        this.notificationsService.flashNotification(
          'failed',
          'We are unable to process your request at this time. Please try again later.',
          false,
          'dismiss'
        );
      }
      this.spinner.hide();
    },
      err => {
        this.spinner.hide();
        this.notificationsService.flashNotification(
          'failed',
          'We are unable to process your request at this time. Please try again later.',
          false,
          'dismiss'
        );
      });
  }

  /** Closing the dialog box - we are setting the form to empty */
  onNoClick(): void {
    this.dialogRef.close();
    this.initializeScheduleMoveForm();
  }

  /** destroys the object */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
