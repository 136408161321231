import { Component, OnInit, ViewChild } from '@angular/core';
import {
  SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';


@Component({
  selector: 'app-reward-animation',
  templateUrl: './reward-animation.component.html',
  styleUrls: ['./reward-animation.component.scss']
})
export class RewardAnimationComponent implements OnInit {
  public show = true;
  public homeValue = 'Home Value';
  public cashBack = 'Cash Back';

  public slides = [
    {
      home: this.homeValue,
      homevalue: '200,000+ USD',
      cashback: this.cashBack,
      cashvalue: '1,100 USD'
    },
    {
      home: this.homeValue,
      homevalue: '300,000+ USD',
      cashback: this.cashBack,
      cashvalue: '1,600 USD'
    },
    {
      home: this.homeValue,
      homevalue: '500,000+ USD',
      cashback: this.cashBack,
      cashvalue: '2,500+ USD'
    },
  ];

  public type = 'component';

  public disabled = false;

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'vertical',
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    loop: true
  };

  constructor(private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.name.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-REWARD_ANIMATION', 'REWARD_ANIMATION');
      });
  }

}
