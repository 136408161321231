import { PersistenceService, StorageType } from 'angular-persistence';
import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  OnDestroy,
  ElementRef,
  HostListener
} from '@angular/core';
import { CandidateNeedsAssessment } from '../../../core/models/candidateneeds-assessment.model';
import { FormGroup, FormBuilder, Validators, ValidationErrors, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS, DateAdapter, NativeDateAdapter } from '@angular/material';
import { DatePipe } from '@angular/common';
import {
  Overlay,
  OverlayConfig,
  OverlayRef
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PopupPositionService } from '../../../core/services/popup-position.service';
import { NotificationsService } from '../../../core/services/notifications.service';
import { Subscription, of } from 'rxjs';
import { CandidateNeedsAssessmentService } from '../../../core/services/candidateneeds-assessment.service';
import * as constants from '../../../core/models/constants';
import { OverlayTooltipComponent } from '../overlay-tooltip/overlay-tooltip.component';
import { DateAdapterService } from '../../../core/services/date-adapter.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LocationService } from '../../../core/services/location.service';
import { Address } from '../../../core/models/address.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { CloseScrollStrategyConfig } from '@angular/cdk/overlay/typings/scroll/close-scroll-strategy';
import { Router } from '@angular/router';
import { CandidateNeedsAssessmentSharedService } from '../../../core/services/candidateneeds-assessment-shared.service';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';

/**
 * Used to store the errormessages
 */
export const errorMessages: { [key: string]: string } = {
  Contact: 'You must enter preferred contact number',
  CurrentAddress: 'You must enter Current Address',
  DestinationAddress: 'You must enter Destination Address',
  PhoneNumbervalidity: 'Special characters are not allowed',
  Street: 'You must enter Street address',
  Town: 'You must enter Town address',
  State: 'You must enter State name',
  ZIP: 'You must enter ZIP code',
  City: 'You must select city',
  InvalidAddress: 'Results not found'
};
/**
 * Used to store date formats
 */
export const APP_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input'
  }
};
/**
 * Base component to review and submit candidate details
 */
@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: DateAdapterService },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})
export class ReviewComponent implements OnInit, OnDestroy {

  /**
   * Getting candidate details as input from parent component
   */
  @Input() needsAssessment: CandidateNeedsAssessment;
  /**
   * Used to store count
   */
  count: number;
  /**
   * Used to show the edit option for relocation count
   */
  isRelocationCountDisabled = true;
  /**
   * Used to define the form containing candidate details
   */
  reviewForm: FormGroup;
  /**
   * Used to check submit status
   */
  submitStatus = false;
  /**
   * Used to store start date value
   */
  startDate: string;
  /**
   * Used to store end date value
   */
  endDate: string;
  /**
   * Used to split current address field to split into different fields on edit action
   */
  splitAddress = false;

  /**
   * Relocation count option
   */
  relocateOption: Array<string>;
  /**
   * Used to store min start date value
   */
  excludeFields = ['currentAddress', 'streetAddress', 'Town', 'State', 'ZIP'];

  minStartDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + constants.moveDate);
  /**
   * Used to store min end date value
   */
  minEndDate = new Date(
    new Date(this.minStartDate).getFullYear(),
    new Date(this.minStartDate).getMonth(),
    new Date(this.minStartDate).getDate() + constants.moveDate);
  /**
   * Used to refer to errorMessages
   */
  errors = errorMessages;
  /**
   * Subscription prop for unsubscribing services
   */
  private readonly subscription: Subscription = new Subscription();

  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;

  /** screenWidth to hold screen width */
  screenWidth: any;

  /** Stores List of Cites */
  cityList: Array<Address>;

  /** Used to Apply Class to tooltip dynamically */
  isRoomToolTipActive = false;
  isHighValueGoodsActive = false;
  /** Used to Apply Class to tooltip dynamically */
  isDateToolTipActive = false;
  /** flag for dest selected */
  isDestSelected: boolean;
  /** flag to check departure valid*/
  isDestinationValid: boolean;
  /** state list from contamt file*/
  stateList = constants.stateList;
  /** spinner message*/
  spinnerMessage: string;

  /**
   * Base constructor
   * @param formBuilder FormBuilder variable
   * @param candidateService CandidateNeedsAssessment Service variable
   * @param notificationsService Notification service variable
   * @param datePipe DatePipe variable
   * @param overlay Overlay injection
   * @param positionService PopupPositionService injection
   * @param persistenceService Instance PersistenceService
   */
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly candidateService: CandidateNeedsAssessmentService,
    private readonly notificationsService: NotificationsService,
    private readonly datePipe: DatePipe,
    private readonly overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly cityService: LocationService,
    private readonly persistenceService: PersistenceService,
    private readonly spinner: NgxSpinnerService,
    private readonly route: Router,
    private readonly needsAssessmentSvc: CandidateNeedsAssessmentSharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService
  ) {
    // To create form and check for the required field validators
    this.reviewForm = this.formBuilder.group({
      relocationStatus: ['', Validators.required],
      relocatePeopleCount: ['', Validators.required],
      currentAddress: ['', Validators.required, this.noEmptySpaceValidator],
      streetAddress: ['', Validators.required, this.noEmptySpaceValidator],
      Town: ['', Validators.required, this.noEmptySpaceValidator],
      State: ['', Validators.required],
      ZIP: ['', Validators.required, this.noEmptySpaceValidator],
      destination: ['', Validators.required, this.noEmptySpaceValidator],
      currentOwnerStatus: ['', Validators.required],
      currentHomeType: ['', Validators.required],
      contactNumber: ['', [Validators.required, Validators.minLength(10),
      this.regexValidator(new RegExp(/[a-zA-Z]/g), { 'alphabets': true }),
      this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\] ]/g), { 'splcharacter': true })
      ]],
      estimatedMoveStartDate: [],
      estimatedMoveEndDate: [],
      currentNoOfRooms: ['', Validators.required],
      highValueStatus: ['', Validators.required]
    });
    this.getScreenSize();
  }

  // Get screen width
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

  /**
 * used to check wether input feild has empty space
 * @param input form with required fields
 */
  noEmptySpaceValidator(input: AbstractControl): ValidationErrors | null {
    return new Promise((resolve, reject) => {
      if (input.value.trim() === '') {
        resolve({ required: true });
      } else {
        resolve(null);
      }

    });
  }

  /**
   * Intializing the values
   */
  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.spinnerMessage = constants.loadingMessage;
    }, 1000);
    this.needsAssessmentSvc.getCandidateNeedsAssessmentDetails().then(resp => {
      this.needsAssessment.candidateId = resp.candidateId;
      this.needsAssessment.contactNumber = resp.contactNumber;
      if (this.needsAssessment.familyDetails.familyRelocationStatus === 'No') {
        this.relocateOption = constants.relocateOptionNo;
      } else {
        this.relocateOption = constants.relocateOptionYes;
      }
      this.reviewForm.patchValue({
        relocationStatus: this.needsAssessment.familyDetails.familyRelocationStatus,
        relocatePeopleCount: this.needsAssessment.familyDetails
          .noOfRelocatePeople,
        currentAddress: this.needsAssessment.departureAddr.fullAddress,
        streetAddress: this.needsAssessment.departureAddr.streetAddress,
        Town: this.needsAssessment.departureAddr.city,
        State: this.needsAssessment.departureAddr.state,
        ZIP: this.needsAssessment.departureAddr.zipcode,
        destination: this.needsAssessment.destinationAddr.fullAddress,
        currentOwnerStatus: this.needsAssessment.residenceDetails.ownerStatus,
        currentHomeType: this.needsAssessment.residenceDetails.homeType,
        contactNumber: this.needsAssessment.contactNumber,
        estimatedMoveStartDate: this.minStartDate,
        estimatedMoveEndDate: this.minEndDate,
        currentNoOfRooms: this.needsAssessment.residenceDetails.noOfRooms,
        highValueStatus: this.needsAssessment.highValueGoods
      });
      this.count = this.needsAssessment.residenceDetails.noOfRooms;
      this.reviewForm.disable();
      this.reviewForm.get('estimatedMoveStartDate').enable();
      this.reviewForm.get('estimatedMoveEndDate').enable();
      /**
      * Increment end date needs to be defaulted to 14 days past start date.
      */
      this.reviewForm.controls['estimatedMoveStartDate'].valueChanges.subscribe(currentStartDate => {
        this.minEndDate = new Date(
          new Date(currentStartDate).getFullYear(),
          new Date(currentStartDate).getMonth(),
          new Date(currentStartDate).getDate() + constants.moveDate);
        this.reviewForm.get('estimatedMoveEndDate').setValue(this.minEndDate);
      });

      if (this.reviewForm.controls.relocationStatus.value === 'No') {
        this.isRelocationCountDisabled = false;
      }
      this.reviewForm.controls['relocatePeopleCount'].setValue(this.needsAssessment.familyDetails
        .noOfRelocatePeople, { onlySelf: true });
      setTimeout(() => {
        this.spinner.hide();
      }, 5000);
    });
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.name.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-REVIEW', 'REVIEW');
      });
  }

  /**
   * Increment value on click
   */
  incNum() {
    // increments the count if count is less than maxRoomLimit
    if (this.count < constants.maxRoomLimit) {
      this.count = this.count + 1;
      this.reviewForm.get('currentNoOfRooms').setValue(this.count);
    }
  }

  /**
   * Decrement value on click
   */
  decNum() {
    // check the condition
    if (this.count > 1) {
      this.count = this.count - 1;
      this.reviewForm.get('currentNoOfRooms').setValue(this.count);
    }
  }
  /**
   * Storing the candidate details and sending them to service on submitting the form
   * @param form Form containing candidate details
   */
  submit(form) {
    this.spinner.show();
    this.submitStatus = true;
    this.needsAssessment.familyDetails.familyRelocationStatus =
      form.controls['relocationStatus'].value;
    this.needsAssessment.familyDetails.noOfRelocatePeople =
      form.controls['relocatePeopleCount'].value;
    this.needsAssessment.departureAddr.streetAddress = form.controls['streetAddress'].value.trim();
    this.needsAssessment.departureAddr.city = form.controls['Town'].value.trim();
    this.needsAssessment.departureAddr.state = form.controls['State'].value.trim();
    this.needsAssessment.departureAddr.zipcode = form.controls['ZIP'].value.trim();
    this.needsAssessment.destinationAddr.streetAddress = '';
    this.needsAssessment.destinationAddr.city = form.controls[
      'destination'
    ].value.split(',')[0];
    this.needsAssessment.destinationAddr.state = form.controls[
      'destination'
    ].value.split(',')[1].trim();
    this.needsAssessment.destinationAddr.country = form.controls[
      'destination'
    ].value.split(',')[2];
    this.needsAssessment.destinationAddr.fullAddress = form.controls[
      'destination'].value;
    this.needsAssessment.departureAddr.country = 'USA';
    this.needsAssessment.destinationAddr.zipcode = '';
    this.needsAssessment.residenceDetails.ownerStatus =
      form.controls['currentOwnerStatus'].value;
    this.needsAssessment.residenceDetails.homeType =
      form.controls['currentHomeType'].value;
    this.needsAssessment.residenceDetails.noOfRooms =
      form.controls['currentNoOfRooms'].value;
    this.needsAssessment.contactNumber = form.controls['contactNumber'].value;
    this.startDate = form.controls['estimatedMoveStartDate'].value;
    this.needsAssessment.estimatedMoveStartDate = this.datePipe.transform(
      this.startDate,
      'yyyy-MM-dd'
    );
    this.endDate = form.controls['estimatedMoveEndDate'].value;
    this.needsAssessment.estimatedMoveEndDate = this.datePipe.transform(
      this.endDate,
      'yyyy-MM-dd'
    );
    this.needsAssessment.highValueGoods = form.controls['highValueStatus'].value;
    delete this.needsAssessment.completedStep;

    this.subscription.add(
      this.candidateService
        .addCandidateAssessmentDetails(this.needsAssessment)
        .subscribe(status => {
          if (status.description) {
            this.needsAssessmentSvc.updateCandidateNeedsAssesment(this.needsAssessment);
            this.notificationsService.verticalPosition = 'bottom';
            this.notificationsService.autoHide = 5000;
            this.notificationsService.flashNotification(
              'success',
              'Thank you for completing the Needs Assessment. We will alert you when your relocation package is ready',
              true,
              'dismiss'
            );
            this.notificationsService.snackBarDismissEvent.afterDismissed().subscribe(response => {
              if (response) {
                this.route.navigate(['/manage-move']);
              }
            });
          } else {
            this.submitStatus = true;
            this.notificationsService.flashNotification(
              'failed',
              'We are unable to process your request at this time. Please try again later.',
              false,
              'dismiss'
            );
          }
          this.spinner.hide();
        },
          err => {
            this.spinner.hide();
          })
    );
  }
  /**
   * Edit the field on click of edit icon
   * @param formControlName field to edit
   */
  edit(formControlName) {
    if (!((this.reviewForm.get('streetAddress').hasError('required')) || (this.reviewForm.get('Town').hasError('required'))
      || (this.reviewForm.get('State').hasError('required')) || (this.reviewForm.get('ZIP').hasError('required')))) {
      this.splitAddress = false;
    }
    if (formControlName === 'currentAddress') {
      this.splitAddress = true;
      this.reviewForm.get('streetAddress').enable();
      this.reviewForm.get('Town').enable();
      this.reviewForm.get('State').enable();
      this.reviewForm.get('ZIP').enable();
    }
    this.reviewForm.get(formControlName).enable();
    this.reviewForm.get('estimatedMoveStartDate').enable();
    this.reviewForm.get('estimatedMoveEndDate').enable();
    formControlName === 'destination' ? this.submitStatus = true : this.submitStatus = false;
  }

  /**
   * Function for onblur when control is taken away from the field
   * @param formControlName field to exit
   */
  onBlurMethod(formControlName) {
    if (this.reviewForm.get(formControlName).valid === true) {
      this.reviewForm.get(formControlName).disable();
      this.reviewForm.get('estimatedMoveStartDate').enable();
      this.reviewForm.get('estimatedMoveEndDate').enable();
    }
    if (formControlName === 'currentAddress') {
      const street = this.reviewForm.get('streetAddress').value;
      const city = this.reviewForm.get('Town').value;
      const state = this.reviewForm.get('State').value;
      const zip = this.reviewForm.get('ZIP').value;

      if (!(street === '' || city === '' || state === '' || zip === '')) {
        const fullAddr = street.concat(', ').concat(city).concat(', ').concat(state).concat(', ').concat(zip);
        this.needsAssessment.departureAddr.fullAddress = fullAddr;
        this.reviewForm.get('currentAddress').setValue(fullAddr);
      } else {
        this.reviewForm.get('currentAddress').setValue('');
      }
    }
  }

  /**
   * Function for onInput when control is in the field
   */
  onInput() {
    const controls = this.reviewForm.controls;
    for (const name in controls) {
      if (!(this.excludeFields.indexOf(name) > -1) &&
        ((controls[name].invalid) ||
          (controls[name].disabled &&
            !controls[name].value))) {
        this.submitStatus = false;
      } else if (this.excludeFields.indexOf(name) > -1 && controls[name].invalid) {
        this.submitStatus = false;
      }
    }
    if ((this.reviewForm.get('streetAddress').value.trim() === '') ||
      (this.reviewForm.get('Town').value.trim() === '') ||
      (this.reviewForm.get('State').value.trim() === '') ||
      (this.reviewForm.get('ZIP').value.trim() === '')) {
      this.submitStatus = false;
    }
  }

  /**method to be called on destination input change */
  onDestInput(search) {
    this.submitStatus = true;
    of(search)
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(searchTerm => {
          searchTerm = searchTerm.trim();
          if (searchTerm) {
            this.isDestSelected = false;
            return this.cityService.getCities(searchTerm);
          }
        })
      )
      .subscribe(res => {
        this.cityList = res;
        if (this.cityList.length === 0 && this.cityList) {
          this.reviewForm.controls['destination'].setErrors({
            invalidAddress: true
          });
        }
      }
      );
    if (search.trim() === '') {
      this.cityList = [];
    }
  }

  /** on dest dropdown close */
  onDestinationSelectionClosed() {
    this.submitStatus = false;
    if (!(this.isDestSelected && this.reviewForm.controls['destination'].value)) {
      this.reviewForm.controls['destination'].setErrors({
        invalidAddress: true
      });
    }
    this.cityList = [];
  }

  /** on dest selection */
  onDestinationSelected() {
    this.submitStatus = true;
    this.isDestSelected = true;
    this.cityList = [];
  }
  /**method to be called on keydown event for destination */
  onDestTabOut() {
    this.reviewForm.controls['destination'].markAllAsTouched();
    this.onFocusout();
  }
  /** Method to check address validation on blur event */
  onFocusout() {
    if (this.reviewForm.controls['destination'].dirty) {
      this.isDestinationValid = false;
      if (!(this.isDestSelected && this.reviewForm.controls['destination'].value)) {
        this.reviewForm.controls['destination'].setErrors({
          invalidAddress: true
        });
      } else {
        this.submitStatus = false;
      }
    } else {
      this.submitStatus = false;
    }
  }
  /**
   * Sets default value for relocating people count based on relocation status
   */
  setRelocatePeopleCount() {
    this.isRelocationCountDisabled = true;
    if (this.reviewForm.controls.relocationStatus.value === 'No') {
      this.isRelocationCountDisabled = false;
      this.relocateOption = constants.relocateOptionNo;
      this.reviewForm.controls['relocatePeopleCount'].setValue('1', { onlySelf: true });
    } else {
      this.relocateOption = constants.relocateOptionYes;
      this.reviewForm.controls['relocatePeopleCount'].setValue('2', { onlySelf: true });
    }
  }

  /**
   * Regex validation.Return true if value matches with given regex
   * @param regex Regular expression
   * @param error instence of ValidationErrors
   */
  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const patternCheck = control.value.toString();
      const valid = patternCheck.match(regex);
      return valid ? error : null;
    };
  }

  /** Open the tooltip dialog */
  openTooltipDialog(key: string, evt: MouseEvent) {
    setTimeout(() => {
      document.getElementById('tooltip-help-info').focus();
    }, 500);
    const rect = (evt.currentTarget as Element).getBoundingClientRect();
    const target = this.getPositionByEvents(rect);
    const element = new ElementRef(target);
    const positionStrategy = this.placeByPositionStrategy(element);
    const thresholdScroll: CloseScrollStrategyConfig = {
      threshold: 50
    };
    const overlayConfig = new OverlayConfig({
      width: 250,
      panelClass: 'overlay-tooltip-pane',
      hasBackdrop: true,
      backdropClass: 'mat-backdrop-transparent',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(thresholdScroll),
      disposeOnNavigation: true
    });
    this.overlayRef = this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(OverlayTooltipComponent, null, this.positionService.createInjector({
      keyString: key,
      clientX: rect.left,
      clientY: rect.top,
      screenWidth: this.screenWidth,
      overlayRef: this.overlayRef
    }));
    this.overlayRef.attach(containerPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
    });
    this.overlayRef.detachments().subscribe(() => {
      this.isRoomToolTipActive = false;
      this.isHighValueGoodsActive = false;
      this.isDateToolTipActive = false;
    });
  }

  /** get the position by events of the target */
  getPositionByEvents(rect: ClientRect) {
    return {
      getBoundingClientRect: (): ClientRect => ({
        bottom: rect.top,
        height: rect.height,
        left: rect.left + (rect.right - rect.left) / 2,
        right: rect.right,
        top: rect.top,
        width: rect.width
      }),
    };
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);
  }

  /**
   * Destroys the subscription object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
