import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ManageMoveService } from '../../../../core/services/manage-move.service';
import { RelocationBudgetDetails } from '../../../../core/models/relocation-budget.model';
import {LoggerService} from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  /** Recieving transferee Details from parent component */
  @Input() transfereeDetails: RelocationBudgetDetails;
  @Output() onCloseNotification: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Base Constructor
   */
  constructor(private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  /**
   * Initialization
   */
  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
    .subscribe(response => {
      const userId: any = response.name.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-NOTIFICATIONS', 'NOTIFICATIONS');
    });
  }

  closeNotification() {
    this.onCloseNotification.emit();
  }
}
