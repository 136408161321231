/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sensitive-info-mask.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./sensitive-info-mask.component";
import * as i4 from "@angular/platform-browser";
var styles_SensitiveInfoMaskComponent = [i0.styles];
var RenderType_SensitiveInfoMaskComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SensitiveInfoMaskComponent, data: {} });
export { RenderType_SensitiveInfoMaskComponent as RenderType_SensitiveInfoMaskComponent };
function View_SensitiveInfoMaskComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "unmask icon"], ["aria-label", "Unmask Field"], ["class", "unmask-icon"], ["src", "../../../assets/images/candidate-assessment/visibility.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reveal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_SensitiveInfoMaskComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "mask icon"], ["aria-label", "Mask Field"], ["class", "mask-icon"], ["src", "../../../assets/images/candidate-assessment/visibility-off.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reveal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_SensitiveInfoMaskComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SensitiveInfoMaskComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SensitiveInfoMaskComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isRevealed; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isRevealed; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maskedString; _ck(_v, 1, 0, currVal_0); }); }
export function View_SensitiveInfoMaskComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sensitive-info-mask", [], null, null, null, View_SensitiveInfoMaskComponent_0, RenderType_SensitiveInfoMaskComponent)), i1.ɵdid(1, 114688, null, 0, i3.SensitiveInfoMaskComponent, [i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SensitiveInfoMaskComponentNgFactory = i1.ɵccf("app-sensitive-info-mask", i3.SensitiveInfoMaskComponent, View_SensitiveInfoMaskComponent_Host_0, { type: "type", value: "value", dialingCode: "dialingCode" }, {}, []);
export { SensitiveInfoMaskComponentNgFactory as SensitiveInfoMaskComponentNgFactory };
