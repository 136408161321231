import { Component, OnInit, Input, ElementRef, HostListener } from '@angular/core';
import { RelocationBudgetDetails } from '../../../../core/models/relocation-budget.model';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ScheduleMoveComponent } from '../../schedule-move/schedule-move.component';
import { ServiceDetails } from '../../../../core/models/services.model';
import { InvitationSentComponent } from '../invitation-sent/invitation-sent.component';
import { LoggerService } from '../../../../core/services/logger.service';
import { LoggedInUserService } from '../../../../core/services/loggedin-user-details.service';
import { CloseScrollStrategyConfig } from '@angular/cdk/overlay/typings/scroll/close-scroll-strategy';
import { OverlayConfig, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { OverlayTooltipComponent } from 'src/app/public/candidate-assessment/overlay-tooltip/overlay-tooltip.component';
import { PopupPositionService } from 'src/app/core/services/popup-position.service';
import { Subscription } from 'rxjs';
import {
  OptOutHighValueGoodsModalComponent
} from '../../high-value-goods/opt-out-high-value-goods-modal/opt-out-high-value-goods-modal.component';
import { ManageMoveSharedService } from '../../../../core/services/manage-move-shared.service';
import * as constants from '../../../../core/models/constants';
import { DatePipe } from '@angular/common';
import { PermissionsService } from 'src/app/core/services/permissions.service';

/**
 * Base component to review and submit candidate details
 */
@Component({
  selector: 'app-explore-destination',
  templateUrl: './explore-destination.component.html',
  styleUrls: ['./explore-destination.component.scss']
})
export class ExploreDestinationComponent implements OnInit {

  /** Recieving transferee Details from parent component */
  @Input() transfereeDetails: RelocationBudgetDetails;
  /** Stores Services */
  services: ServiceDetails[] = [];
  /** Stores data about schedule move approved or not */
  isScheduleMoveApproved: boolean;
  /** stores about agent info avilable or not */
  isAgentInfoAvailable: boolean;
  /** Used to Apply Class to tooltip dynamically */
  isHighValueGoodsToolTipActive = false;
  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;
  /** screenWidth to hold screen width */
  screenWidth: any;
  /** tooltip icon active class */
  tooltipIconActive = 'active';
  /** stores tooltip width */
  tooltipWidth: number;
  /**used to store currency symbol */
  currencySymbol: string;
  /** high value goods submitted date */
  hvgSubmittedOn: any;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  canUpdateHVGItem = true;
  /**
   * Base Constructor
   * @param dialog Instance of MatDialog
   * @param snackBar Instance of MatSnackBar
   */
  constructor(
    public dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly manageMoveSharedService: ManageMoveSharedService,
    private readonly datePipe: DatePipe,
    private readonly permissionsSvc: PermissionsService
    ) {
  }

  // Get screen width
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 960) {
      this.tooltipWidth = 204;
    } else {
      this.tooltipWidth = 350;
    }
  }

  /**
   * Initialization
   */
  ngOnInit() {
    this.canUpdateHVGItem = this.permissionsSvc.getCapabilities();
    this.services = this.transfereeDetails.services;
    this.currencySymbol = constants.currency;
    if (this.transfereeDetails.hvgSubmittedOn) {
      this.hvgSubmittedOn = this.datePipe.transform(this.transfereeDetails.hvgSubmittedOn, 'yyyy-MM-dd');
    }
    this.isScheduleMoveApproved = this.transfereeDetails.timeLineDates.filter(x => x.serviceType === 'estimated')
      .some(y => y.endDate !== null || y.startDate !== null);

    this.isAgentInfoAvailable = this.services.length > 0 && this.services[0].service === 'Van Line' ? true : false;

    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.name.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-EXPLORE_DESTINATION', 'EXPLORE_DESTINATION');
      });
  }

  openOptOutHighValueModal() {
    const dialogRef = this.dialog.open(OptOutHighValueGoodsModalComponent, {
      panelClass: 'dialogMainContainer',
      autoFocus: false,
      data: this.transfereeDetails.candidateId
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.transfereeDetails.hasHighValueGoods = false;
        this.manageMoveSharedService.candidateDetails.subscribe(candidateDetails => {
          candidateDetails.hasHighValueGoods = false;
          this.manageMoveSharedService.updateData(candidateDetails);
        });
      }
    }));
  }

  /**
   * Loads the ScheduleMove Component in MatDialog
   */
  openDialog() {
    const dialogRef = this.dialog.open(ScheduleMoveComponent, {
      panelClass: 'schedule-dialogMainContainer',
      autoFocus: false,
      data: this.transfereeDetails
    });

    dialogRef.afterClosed().subscribe((response: boolean) => {
      if (response) {
        this.snackBar.openFromComponent(InvitationSentComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          data: `Request Sent`,
          duration: 5000
        });
      }
    });
  }

  /** Open the tooltip dialog */
  openTooltipDialog(key: string, evt: MouseEvent) {
    evt.stopPropagation();
    evt.preventDefault();
    const rect = (evt.currentTarget as Element).getBoundingClientRect();
    const target = this.getPositionByEvents(rect);
    const element = new ElementRef(target);
    const positionStrategy = this.placeByPositionStrategy(element);
    const thresholdScroll: CloseScrollStrategyConfig = {
      threshold: 50
    };
    const overlayConfig = new OverlayConfig({
      width: 250,
      panelClass: 'overlay-tooltip-pane',
      hasBackdrop: true,
      backdropClass: 'mat-backdrop-transparent',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(thresholdScroll),
      disposeOnNavigation: true
    });
    this.overlayRef = this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(OverlayTooltipComponent, null, this.positionService.createInjector({
      keyString: key,
      clientX: rect.left,
      clientY: rect.top,
      screenWidth: this.screenWidth,
      overlayRef: this.overlayRef
    }));
    this.overlayRef.attach(containerPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
    });
    this.overlayRef.detachments().subscribe(() => {
      this.isHighValueGoodsToolTipActive = false;
    });
  }

  /** get the position by events of the target */
  getPositionByEvents(rect: ClientRect) {
    return {
      getBoundingClientRect: (): ClientRect => ({
        bottom: rect.top,
        height: rect.height,
        left: rect.left + (rect.right - rect.left) / 2,
        right: rect.right,
        top: rect.top,
        width: rect.width
      }),
    };
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);
  }
}
