<div class="candidate-NA-Manincont">
  <header class="header">
    <mat-toolbar fxLayout="row">
      <div class="title" fxLayoutAlign="center center">
        <mat-icon class="transferee-logo" svgIcon="logoMobilifyWhite"></mat-icon>
      </div>
      <div class="sign-out clickable" (click)="logout()" role="button" (keyup.enter)="logout()" tabindex="0">
        <span>Log Out</span>&nbsp;&nbsp;<mat-icon svgIcon="signOut" id="logout" aria-labelledby="logout"></mat-icon>
      </div>
    </mat-toolbar>
  </header>
  <div fxLayout="row" role="main" id="maincontent" fxLayout.xs="column" fxFlexFill>

    <div fxFlex="75%" class="needs-Leftsec" fxFlex.sm="70%" fxFlex.xs="100%"
      [ngClass]="{ 'single-column': stepper.selectedIndex==9, 'welcome': stepper.selectedIndex==0 }">
      <mat-horizontal-stepper #stepper (selectionChange)="onStepChange($event)">
        <mat-step>
          <div *ngIf="stepper.selectedIndex==0" class="begin-bg">
            <div class="welcome-content">
              <label id="example-radio-group-label">
                <h1 class="greeting">Welcome to {{CandidateName}}!</h1>
              </label>
              <p class="introduction">Please take a few minutes to complete the Needs Assessment questionnaire. This
                information will be used in creating a customized relocation budget. The details you provide will be
                made available to your HR contact as well as our professional van line partner to establish the cost
                associated with moving your household goods.</p>
            </div>
            <!-- Button section starts -->
            <div class="candidate-btn-container">
              <button mat-button class="candidate-btn contained-button" (click)="changeStep(1)">Let's Begin</button>
            </div>
            <!-- Button section ends -->
          </div>

        </mat-step>
        <mat-step>
          <div *ngIf="stepper.selectedIndex==1">
            <app-relocation-details [needsAssessment]="assessmentModel" [step]="1" (notify)="onNotify($event)">
            </app-relocation-details>
          </div>
        </mat-step>
        <mat-step>
          <div *ngIf="stepper.selectedIndex==2">
            <app-relocation-details [needsAssessment]="assessmentModel" [step]="2" (notify)="onNotify($event)"
              (previousStep)="previousStep($event)">
            </app-relocation-details>
          </div>
        </mat-step>
        <mat-step>
          <div *ngIf="stepper.selectedIndex==3">
            <app-address-details [needsAssessment]="assessmentModel" [step]="3" (notify)="onNotify($event)"
              (previousStep)="previousStep($event)">
            </app-address-details>
          </div>
        </mat-step>
        <mat-step>
          <div *ngIf="stepper.selectedIndex==4">
            <app-address-details [needsAssessment]="assessmentModel" [step]="4" (notify)="onNotify($event)"
              (previousStep)="previousStep($event)">
            </app-address-details>
          </div>
        </mat-step>
        <mat-step>
          <div *ngIf="stepper.selectedIndex==5">
            <app-property-details [needsAssessment]="assessmentModel" [step]="5" (notify)="onNotify($event)"
              (previousStep)="previousStep($event)">
            </app-property-details>
          </div>
        </mat-step>
        <mat-step>
          <div *ngIf="stepper.selectedIndex==6">
            <app-property-details [needsAssessment]="assessmentModel" [step]="6" (notify)="onNotify($event)"
              (previousStep)="previousStep($event)">
            </app-property-details>
          </div>
        </mat-step>
        <mat-step>
          <div *ngIf="stepper.selectedIndex==7">
            <app-property-details [needsAssessment]="assessmentModel" [step]="7" (notify)="onNotify($event)"
              (previousStep)="previousStep($event)">
            </app-property-details>
          </div>
        </mat-step>
        <mat-step>
          <div *ngIf="stepper.selectedIndex==8">
            <app-high-value-goods-details [needsAssessment]="assessmentModel" [step]="8" (notify)="onNotify($event)"
              (previousStep)="previousStep($event)">
            </app-high-value-goods-details>
          </div>
        </mat-step>
        <mat-step>
          <div *ngIf="stepper.selectedIndex==9">
            <app-review [needsAssessment]="assessmentModel"></app-review>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>

    <div *ngIf="stepper.selectedIndex!==9" fxFlex="25%" class="needs-Rightsec" fxFlex.sm="30%" fxFlex.xs="100%"
      [ngClass]="{'welcome': stepper.selectedIndex==0 }">
      <app-rightsection [displayText]="displayText"></app-rightsection>
    </div>
  </div>
</div>