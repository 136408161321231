import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FAQ_CONTENT, FaqComponentTemplateString } from '../../../core/models/constants';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-details.service';


interface FaqItem {
  title: string;
  questions: Array<{
    title: string;
    answer: Array<{
      content: string;
      type: string;
    }>,
    pannelState?: boolean;
  }>;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit {
  /** Stores all the strings used in the template */
  templateString = FaqComponentTemplateString;
  /** Stores copy of the original data */
  faqItems: Array<FaqItem>;
  /** Stores the values to be displayed */
  faqItemsToDisplay: Array<FaqItem>;
  /** Stores the filter value */
  filterText: string;
  /** Stores if there are any faq item to be displayed */
  noResults: boolean;
  private readonly keyUpSubject: Subject<string> = new Subject();

  constructor(private readonly Logger: LoggerService, private readonly loggedInUserService: LoggedInUserService) {
    this.filterText = '';
    this.faqItems = JSON.parse(JSON.stringify(FAQ_CONTENT));
    this.faqItemsToDisplay = JSON.parse(JSON.stringify(this.faqItems));
    this.noResults = false;
  }

  ngOnInit() {
    // Delay the filter function call
    this.keyUpSubject
      .pipe(debounceTime(300))
      .pipe(distinctUntilChanged())
      .subscribe(searchTextValue => {
        this.filterResults(searchTextValue);
      });
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.name.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-FAQ', 'FAQ');
      });
  }
  /**
  * Function to filter results while searching
  * @param filterVal -Filter value passed
  */
  filterResults(filterVal) {
    filterVal = filterVal.toLowerCase();
    this.filterText = filterVal;
    this.noResults = true;

    // Check if filter has content
    if (filterVal.trim().length > 0) {
      this.faqItemsToDisplay = this.faqItems.map(element => {
        const filteredFaq = {
          title: element.title,
          // Filter the question
          questions: element.questions.filter(question => {
            question.pannelState = false;
            let returnValue = false;
            // Check if question contains key word
            if (question.title.toLowerCase().indexOf(filterVal) >= 0) {
              returnValue = true;
            }
            // Check if answer contains key word
            const answerContainsText = question.answer.some(answer => answer.content.toLowerCase().indexOf(filterVal) >= 0);
            if (answerContainsText) {
              question.pannelState = true;
              returnValue = true;
            }
            return returnValue;
          }),
        };
        if (filteredFaq.questions.length > 0) {
          this.noResults = false;
        }
        return filteredFaq;
      });
    } else {
      // Reset the search index
      this.faqItemsToDisplay = JSON.parse(JSON.stringify(FAQ_CONTENT));
      this.noResults = false;
    }
  }
  /**
   *
   * @param filterVal -Filter value passed from template
   */
  onKeyUp(filterVal) {
    this.keyUpSubject.next(filterVal);
  }
}
