import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { HighValueService } from '../../../core/services/high-value.service';
import { HighValueModalComponent } from './high-value-modal/high-value-modal.component';
import { HighValueItemsGetResponse } from '../../../core/models/high-value-items-get-response.model';
import { HighValueItems } from 'src/app/core/models/high-value-items.model';
import * as constants from '../../../core/models/constants';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SubmitHighValueGoodsModalComponent } from './submit-high-value-goods-modal/submit-high-value-goods-modal.component'
import { NotificationsService } from '../../../core/services/notifications.service';
import { ArrayUtil } from '../../../core/shared/array.util';
import { ManageMoveSharedService } from '../../../core/services/manage-move-shared.service';
import { InvitationSentComponent } from '../right-maincontent/invitation-sent/invitation-sent.component';
import { HighValueGoods } from '../../../core/models/high-value-goods.model';
import { HighValueItemAPIResponse } from 'src/app/core/models/high-value-item-response.model';
import { DatePipe } from '@angular/common';
import { PermissionsService } from '../../../core/services/permissions.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-high-value-goods',
  templateUrl: './high-value-goods.component.html',
  styleUrls: ['./high-value-goods.component.scss']
})
export class HighValueGoodsComponent implements OnInit, OnDestroy {

  /* High Value List Item */
  category1: [];
  category2: [];
  category3: [];
  /**used to store tittle for High Value Goods*/
  templateString = {
    HEADING: 'My High Value Goods',
    HEADING_SUMMARY: `Please list each of your High Value Goods based on the item entry direction below`,
    ADD_HIGH_BUTTON: 'Add High Value Item',
    SUBMIT_BUTTON: 'Submit Listed Item'
  };
  highValueGoods: Array<HighValueItems>;
  highValueGoodsTotalAmount = 0;
  hvgSubmittedOn: string;
  candidateId: string;
  /**used to store currency symbol */
  currencySymbol: string;
  sortOptions = [
    {value: 'itemType', viewValue: 'Type of Item'},
    {value: 'replacementValue', viewValue: 'Replacement Value'}
  ];
  selectedSortOption = 'itemType';
  isHighValueSubmitted = false;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  canUpdateHVGItem = true;

  constructor(private readonly highValueService: HighValueService,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    private readonly route: ActivatedRoute,
    public notificationsService: NotificationsService,
    private readonly manageMoveSharedService: ManageMoveSharedService,
    public snackBar: MatSnackBar,
    private readonly datePipe: DatePipe,
    public router: Router,
    private readonly permissionsSvc: PermissionsService,
    private readonly titleService: Title
  ) {
    this.titleService.setTitle('My High Value Goods');
  }

  ngOnInit() {
    this.canUpdateHVGItem = this.permissionsSvc.getCapabilities();
    this.highValueService.getHighValueItemCategories()
      .subscribe(response => {
        this.category1 = response['Category 1'];
        this.category2 = response['Category 2'];
        this.category3 = response['Category 3'];
      });
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.candidateId = params.get('candidateId');
      this.getAddedHighValueItems();
    });
    this.currencySymbol = constants.currency;
  }

  addHighValueItem(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(HighValueModalComponent, {
      panelClass: 'dialogMainContainer'
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((highValueGoods: HighValueGoods) => {
      if (highValueGoods) {
        this.spinner.show();
        this.highValueService.sendHighValueItem(highValueGoods).subscribe((response: HighValueItemAPIResponse) => {
          this.spinner.hide();
          if (response && response.statusCode === 200) {
            this.getAddedHighValueItems(true);
            setTimeout(() => {
              this.showNotification(constants.hvgSuccessResponseMsg);
            }, 800);
          } else {
            this.showNotification(constants.apiFailureResponseMsg);
          }
        }, err => {
            this.spinner.hide();
            this.showNotification(constants.apiFailureResponseMsg);
        });
      }
    }));
  }

  editOpen(item, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(HighValueModalComponent, {
      panelClass: 'dialogMainContainer',
      data: item
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((highValueGoods: HighValueGoods) => {
      if (highValueGoods) {
        this.spinner.show();
        this.highValueService.updateHighValueItem(highValueGoods).subscribe((response: HighValueItemAPIResponse) => {
          this.spinner.hide();
          if (response && response.statusCode === 200) {
            this.getAddedHighValueItems(true);
            setTimeout(() => {
              this.showNotification(constants.hvgUpdatedSuccessMsg);
            }, 800);
          } else {
            this.getAddedHighValueItems(true);
            setTimeout(() => {
              this.showNotification(constants.apiFailureResponseMsg);
            }, 800);
          }
        }, err => {
            this.spinner.hide();
            this.showNotification(constants.apiFailureResponseMsg);
        });
      }
    }));
  }

  getAddedHighValueItems(isRefreshData = false) {
    this.spinner.show();
    this.highValueService.getAddedHighValueItems(this.candidateId)
      .subscribe((response: HighValueItemsGetResponse) => {
        this.spinner.hide();
        if (response) {
          this.highValueGoods = response.List;
          this.highValueGoodsTotalAmount = response.totalAmt ? response.totalAmt : 0;
          this.hvgSubmittedOn = response.hvgSubmittedOn;
          if (response.hvgSubmittedOn && response.hvgSubmittedOn.length > 0) {
            this.isHighValueSubmitted = true;
          }
          if (response.hvgSubmittedOn) {
            this.templateString.HEADING_SUMMARY = `Below is a list of the High Value Goods you have submitted.`;
          }
          this.onSortOptionChange();
          if (isRefreshData) {
            this.manageMoveSharedService.candidateDetails.subscribe(candidateDetails => {
              candidateDetails.hvgTotal = this.highValueGoodsTotalAmount;
              this.manageMoveSharedService.updateData(candidateDetails);
            });
          }
        }
        this.spinner.hide();
      });
  }

  openSubmitModal() {
    const dialogRef = this.dialog.open(SubmitHighValueGoodsModalComponent, {
      panelClass: 'dialogMainContainer',
      autoFocus: false,
      data: {
        candidateId: this.candidateId,
        totalItems: this.highValueGoods.length,
        highValueGoodsTotalAmount: this.highValueGoodsTotalAmount
      }
    });
  }

  deleteHVGItem(itemTodelete: string, itemType: string, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.spinner.show();
    this.highValueService.deleteHighValueItem(this.candidateId, itemTodelete)
      .subscribe((response) => {
        this.spinner.hide();
        if (response && response.statusCode === 200) {
          this.getAddedHighValueItems(true);
          setTimeout(() => {
            this.showNotification(`${itemType} Deleted`);
          }, 800);
        } else {
          this.showNotification(constants.apiFailureResponseMsg);
        }
      });
  }

  /** method to show the notification message */
  showNotification(message: string): void {
    this.snackBar.openFromComponent(InvitationSentComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: message,
      duration: 5000
    });
  }

  onSortOptionChange() {
    if ( this.highValueGoods && this.highValueGoods.length > 0 ) {
      this.highValueGoods = ArrayUtil.sortArrayOfObject(
        this.highValueGoods, this.selectedSortOption, 'asc', this.selectedSortOption === 'replacementValue' ? true : false
      );
    }
  }
  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
