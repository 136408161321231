import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { privacyNoticeContent, legalTermsContent, PRIVACY_TERMS_TEMPLATE_STRING } from '../../core/models/privacyConstants';
import { LoggedInUserService } from '../../core/services/loggedin-user-details.service';
import { LoggerService } from '../../core/services/logger.service';

interface PageContentModel {
  content: any;
  class: string;
  type?: string;
}

@Component({
  selector: 'app-privacy-terms',
  templateUrl: './privacy-terms.component.html',
  styleUrls: ['./privacy-terms.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivacyTermsComponent implements OnInit {

  /** Stores the template strings */
  templateString = PRIVACY_TERMS_TEMPLATE_STRING;

  /** Stores the paths for which this component is rendered */
  paths = {
    legalTerms: 'legalTerms',
    privacy: 'privacyNotice'
  };

  /** Stores the content to be displayed on the screen */
  pageContent: Array<PageContentModel>;

  constructor(private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) {
    this.route.url.subscribe(url => {
      if (url[0].path === this.paths.privacy) {
        this.titleService.setTitle('Privacy Statement');
        this.pageContent = privacyNoticeContent;
      } else if (url[0].path === this.paths.legalTerms) {
        this.titleService.setTitle('Terms of Use');
        this.pageContent = legalTermsContent;
      }
    });
  }

  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.name.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'TRANSFEREE-PRIVACY_TERMS', 'PRIVACY_TERMS');
      });
  }

}
