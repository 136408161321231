<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>
<div class="my-profile-page transfree-regular-page">
    <app-budget-header [heading]='templateString.HEADING' [backNavLink]="'/manage-move'"></app-budget-header>

    <div fxLayout="column" fxLayoutGap="0.986em" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="2.5em">

        <div fxFlex.gt-sm="57" class="my-profile-card transfree-content-card">
            <div class="heading" fxLayout="row" fxLayoutGap="1em">
                <mat-icon class="heading-icon" svgIcon="profileIcon" role="img"></mat-icon>
                <div class="title">{{templateString.PROFILE_CARD_TITLE}}</div>
            </div>
            <ng-container *ngTemplateOutlet="myProfilePageForm"></ng-container>
        </div>

        <div fxFlex>
            <div class="change-password-card">
                <p class="change-password-font">
                    <span class="change-password-link clickable" role="button"><a href="#"
                            (click)="canUpdateProfileInfo ? gotoChangePasswordPage($event) : null"
                            [class.disabled]="!canUpdateProfileInfo">Change Password</a></span>
                </p>
            </div>
            <div class="move-details-card transfree-content-card-dark">
                <div class="heading" fxLayout="row" fxLayoutGap="16px">
                    <mat-icon class="icon-check-list" svgIcon="checkListIcon" aria-hidden="false"></mat-icon>
                    <div class="title">{{templateString.DETAILS_CARD_TITLE}}</div>
                </div>
                <ng-container *ngTemplateOutlet="myMoveDetailsPageForm"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #myProfilePageForm>
    <form fxLayout="column" fxLayoutGap="1.53em" [formGroup]="myProfileform">
        <div fxLayout="column" fxLayoutGap="1.25em" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="13.6%">
            <div class="form-input-select sm">
                <mat-form-field appearance="legacy">
                    <mat-label>Salutation</mat-label>
                    <mat-select formControlName="Salutation" class="form-select" #salutationSelect
                        panelClass="form-input-select-option" disableOptionCentering
                        (openedChange)="updateInput($event, 'Salutation')"
                        (selectionChange)="updateInput($event, 'Salutation')">
                        <mat-option *ngFor="let option of salutationOptions" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-select>
                    <a href="javascript:void(0);" [class.disabled]="!canUpdateProfileInfo" matSuffix
                        (click)="canUpdateProfileInfo ? edit('Salutation') : null" title="Edit" aria-label="edit salutation">
                        <mat-icon class="form-icon" [class.disabled]="!canUpdateProfileInfo" aria-hidden="false" svgIcon="editIcon">
                        </mat-icon>
                    </a>
                </mat-form-field>
            </div>

            <div class="form-input" fxFlex>
                <mat-form-field appearance="legacy">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="FirstName" (blur)="onBlurMethod('FirstName')"
                        onfocus="this.select()">
                    <a href="javascript:void(0);" [class.disabled]="!canUpdateProfileInfo" matSuffix
                        (click)="canUpdateProfileInfo ? edit('FirstName') : null" title="Edit" aria-label ="Edit First Name">
                        <mat-icon class="form-icon" [class.disabled]="!canUpdateProfileInfo" aria-hidden="false" svgIcon="editIcon">
                        </mat-icon>
                    </a>
                    <mat-error>
                        {{ getErrorMessage('FIRST_NAME') }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="form-input">
            <mat-form-field appearance="legacy">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="LastName" (blur)="onBlurMethod('LastName')" onfocus="this.select()" aria-label="Last Name Field">
                <a href="javascript:void(0);" [class.disabled]="!canUpdateProfileInfo" matSuffix
                    (click)="canUpdateProfileInfo ? edit('LastName') : null" title="Edit" aria-label ="Edit Last Name">
                    <mat-icon class="form-icon" [class.disabled]="!canUpdateProfileInfo" aria-hidden="false" svgIcon="editIcon"></mat-icon>
                </a>
                <mat-error>
                    {{ getErrorMessage('LAST_NAME') }}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-input">
            <mat-form-field appearance="legacy">
                <mat-label>Preferred Name (optional)</mat-label>
                <input matInput formControlName="PreferredName" (blur)="onBlurMethod('PreferredName')"
                    onfocus="this.select()">
                <a href="javascript:void(0);" [class.disabled]="!canUpdateProfileInfo" matSuffix
                    (click)="canUpdateProfileInfo ? edit('PreferredName') : null" title="Edit" aria-label ="Edit Preferred Name">
                    <mat-icon class="form-icon" [class.disabled]="!canUpdateProfileInfo" aria-hidden="false" svgIcon="editIcon"></mat-icon>
                </a>
            </mat-form-field>
        </div>

        <div class="form-input">
            <mat-form-field appearance="legacy">
                <mat-label>Company Name</mat-label>
                <input matInput formControlName="CompanyName" aria-label="Company Name Field">
            </mat-form-field>
        </div>

        <div class="form-input date-field">
            <mat-form-field appearance="legacy">
                <mat-label>Job Start Date</mat-label>
                <input matInput [value]="myProfileform.get('JobStartDate').value | date:'yyyy-MM-dd'"
                    formControlName="JobStartDate">
            </mat-form-field>
        </div>

        <div class="form-input">
            <mat-form-field appearance="legacy">
                <mat-label class="label">Mobile Phone Number</mat-label>
                <input matInput formControlName="PhoneNumber" aria-label="Mobile Number Field">
                <app-sensitive-info-mask *ngIf="phoneNumber" type="phone" [value]="phoneNumber"
                    [dialingCode]="countryDialingCode"></app-sensitive-info-mask>
            </mat-form-field>
        </div>

        <div class="form-input">
            <mat-form-field appearance="legacy">
                <mat-label class="label">Email Address</mat-label>
                <input matInput formControlName="Email" aria-label="Email Field">
                <app-sensitive-info-mask *ngIf="emailId" type="email" [value]="emailId"></app-sensitive-info-mask>
            </mat-form-field>
        </div>
    </form>
</ng-template>

<ng-template #myMoveDetailsPageForm>
    <form fxLayout="column" fxLayoutGap="0.7em" [formGroup]="moveDetailsform">
        <div class="form-input sm">
            <mat-form-field appearance="legacy">
                <mat-label>Is anyone else relocating with you?</mat-label>
                <input matInput formControlName="FamilyRelocationStatus" aria-label="Response - Family Relo Status">
            </mat-form-field>
        </div>

        <div class="form-input sm">
            <mat-form-field appearance="legacy">
                <mat-label>How many people, including yourself, are relocating?</mat-label>
                <input matInput formControlName="NoOfRelocatePeople" aria-label="Response - No of People Relocating">
            </mat-form-field>
        </div>

        <div class="form-input">
            <mat-form-field appearance="legacy">
                <mat-label>Current Address</mat-label>
                <input matInput formControlName="CurrentAddress" aria-label="Response - Current Address">
            </mat-form-field>
        </div>

        <div class="form-input">
            <mat-form-field appearance="legacy">
                <mat-label>Where are you moving to?</mat-label>
                <input matInput formControlName="DestinationAddress" aria-label="Response - Destination">
            </mat-form-field>
        </div>

        <div class="form-input sm">
            <mat-form-field appearance="legacy">
                <mat-label>Do you currently own or rent?</mat-label>
                <input matInput formControlName="OwnerStatus" aria-label="Response - Own or Rent">
            </mat-form-field>
        </div>

        <div class="form-input home-desc">
            <mat-form-field appearance="legacy">
                <mat-label>How would you describe the home where you live?</mat-label>
                <input matInput formControlName="HomeType" aria-label="Response - Current Home Description">
            </mat-form-field>
        </div>

        <div class="form-input sm">
            <mat-form-field appearance="legacy">
                <mat-label>How many rooms are in your home?</mat-label>
                <input matInput formControlName="NoOfRooms" aria-label="Response - No of rooms in current home">
            </mat-form-field>
        </div>

        <div class="form-input sm">
            <mat-form-field appearance="legacy">
                <mat-label>Are you bringing any individual items valued over $2,500?</mat-label>
                <input matInput formControlName="HighValueGoods" aria-label="Response - Do you have High Value Goods">
            </mat-form-field>
        </div>

    </form>
</ng-template>