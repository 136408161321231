<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-live="assertive" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column" cdkTrapFocus cdkTrapFocusAutoCapture>

    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <h2 *ngIf="!isEdit">{{ highValueTitle }}</h2>
        <h2 *ngIf="isEdit">{{ editHighValueTitle }}</h2>
        <a href="#" tabindex="0" class="close-icon" id="close_icon" (click)="onNoClick($event)" matTooltip="Close" style="color: grey; cursor: pointer;">
            <mat-icon>close</mat-icon>
        </a>
    </div>

    <mat-dialog-content class="middle-section">
        <form fxLayout="column" [formGroup]="highValueItem">

            <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" style="margin-bottom: 15px;">

                <mat-form-field hideRequiredMarker style="width: 100%;">
                    <mat-select placeholder="Type of Item" disableOptionCentering required aria-label="Item" formControlName="Item"
                        [errorStateMatcher]="matcher" (selectionChange)="onSelectItem('Item')">
                        <mat-option *ngFor="let a of enumeratedItemArray" [value]="a.value">
                            {{a.value}}
                        </mat-option>
                    </mat-select>
                    <mat-hint align="end"> {{ itemHintValue }} </mat-hint>
                    <mat-error>
                        {{ errors['Item'] }}
                    </mat-error>
                </mat-form-field>

            </div>

            <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" style="margin-bottom: 15px;">

                <mat-form-field hideRequiredMarker style="width: 100%;">
                    <input matInput appAmountFormat [readonly]="!checkItemSelected()" type="text"
                        placeholder="Replacement value in USD" formControlName="Amount" (keyup)="onKey($event)"
                        (blur)="onKey($event)">
                    <mat-hint align="end"> Current market value for similar quality and value </mat-hint>
                    <mat-error>
                        {{ Amount }}
                    </mat-error>
                </mat-form-field>

            </div>

            <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" style="margin-bottom: 15px;">

                <mat-form-field hideRequiredMarker style="width: 100%;">
                    <input matInput type="text" placeholder="Description of Item" formControlName="Desc" maxlength="50"
                        [(ngModel)]="role">
                    <mat-hint align="end" *ngIf="!checkChar()"> {{maxChars}} Characters Max </mat-hint>
                    <mat-hint align="end" *ngIf="checkChar()"> {{maxChars - role.length}} Characters Left </mat-hint>
                    <mat-error>
                        {{ errors['Desc'] }}
                    </mat-error>
                </mat-form-field>

            </div>

            <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" style="margin-bottom: 15px;">

                <mat-form-field hideRequiredMarker style="width: 100%;">
                    <input matInput type="text" placeholder="ID Number (optional)" formControlName="ID">
                    <mat-hint align="end"> Manufacturer's Serial Number or Modal Number </mat-hint>
                </mat-form-field>

            </div>

        </form>
    </mat-dialog-content>

    <mat-dialog-actions class="btnAction">
        <button class="transferee-text-button cancelBtn" (click)="onNoClick($event)">CANCEL</button>
        <button class="transferee-contained-button addBtn" (click)="addItem()" [disabled]="!checkForAddItem()" *ngIf="!isEdit"
            id="addEditSave">
            Add Item
        </button>
        <button class="transferee-contained-button addBtn" (click)="updateItem()" [disabled]="!checkForAddItem()" *ngIf="isEdit"
            id="addEditSave">
            Save Changes
        </button>
    </mat-dialog-actions>

</div>